import api from "../../../api";

export const getPerformedExams = (params) =>
	api.get(`/lms/exam_applications`, {
		params: {
			...params,
		},
	});

export const getExams = (id, params) =>
	api.get(`/lms/exams/${id}`, {
		params: {
			...params,
		},
	});

export const postExams = (id, object_id, answers) =>
	api.post(`/lms/exams/${id}/submission?${object_id}`, answers);

export const getExamApproval = (userId, courseId) =>
	api.get(
		`/lms/exam_applications?user_iri=/users/${userId}&wherein[course.id]=${courseId}&order[grade_obtained]=desc`
	);

export const getExamLessonApproval = (userId, lessonId, examId) =>
	api.get(
		`/lms/exam_applications?user_iri=/users/${userId}&wherein[lesson.id]=${lessonId}&wherein[exam.id]=${examId}&order[grade_obtained]=desc`
	);