import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h1`
  margin: 20px auto 20px 0;
  line-height: 44px;
  font-size: 35px;
  font-weight: bold;
  color: #333;
`;

export const Description = styled.p`
  margin: 10px auto 15px 0;
  font-size: 14px;
  line-height: 20px;
  color: #333;
`;
