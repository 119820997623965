import React, { useState } from "react";
import { Container, Title, Dropdown } from "./styles";
import { LinkHandler } from "../../../../../Components";

const MenuMobileProfile = ({ dropdown }) => {
	const [isVisible, setIsVisible] = useState(false);

	if (!dropdown) return null;
	return (
		<Container>
			<Title onClick={(e) => setIsVisible((prev) => !prev)}>
				{dropdown.title}
			</Title>
			{isVisible && (
				<Dropdown>
					{dropdown.map((item) => (
						<LinkHandler
							url={item.url}
							target={item.target}
							title={item.title}
							childrenPosition='left'
							key={`dropdown-mobile-${item.id}`}
						/>
					))
					}
				</Dropdown>
			)}
		</Container>
	);
};

export default MenuMobileProfile;
