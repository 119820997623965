import React from "react";
import { stripTags } from "../../utils/manipulateString";
import { Container, Banner, Description } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import Placeholder from "../../assets/images/placeholder.jpg";
import SambaVideo from "../UtilComponents/ContentMedia/SambaVideo"

const About = ({ page }) => {

	if (!page) return null;
	return (
		<Container>
			<PageTitle>{page.title || ""}</PageTitle>
			{page.samba_media_reference &&
				<SambaVideo
					reference={page.samba_media_reference || ""}
				/>
			}
			<br />
			{page.image &&
				<Banner
					src={(page.image.urlPublica) || Placeholder}
					alt='banner'
				/>
			}
			<Description
				dangerouslySetInnerHTML={{
					__html: stripTags(page.content) || "",
				}}
			/>
		</Container>
	);
};

export default About;
