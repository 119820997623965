import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled.div`
  margin: 60px 0;
  padding: 30px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 0%, 97.3%, 0.78);
`;

export const Title = styled.h5`
  margin: 0 0 30px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;

export const Content = styled(GlobalContainer)`
  padding: 31px;
  max-width: 840px !important;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #d6d6d6;
`;
