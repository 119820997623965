import React from "react";
import { stripTags } from "../../../utils/manipulateString";
import { Container, Thumbnail, Title } from "./styles";
import { Button } from "../../../styles/GlobalStyles";
import { LinkHandler } from "../../Components";
import Placeholder from "../../../assets/images/placeholder.jpg";

const CardProfile = ({ course }) => {
  if (!course) return null;

  return (
    <Container>
      <LinkHandler url={`curso-interno/${course.slug}`}>
        <Thumbnail
          url={(course.image && course.image.urlPublica) || Placeholder}
        />
      </LinkHandler>

      <LinkHandler url={`curso-interno/${course.slug}`}>
        <Title dangerouslySetInnerHTML={{ __html: stripTags(course.title) }} />
      </LinkHandler>

      <LinkHandler url={`curso-interno/${course.slug}`}>
        <Button>Acessar</Button>
      </LinkHandler>
    </Container>
  );
};

export default CardProfile;
