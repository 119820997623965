import React, { useState, useEffect } from "react";
import { getExams } from "../../services/endpoints/lms/exams";
import { useExamContext } from "../../contexts/ExamProvider";
import { NoExam } from "./styles";
import {
	PageContainer,
	Header,
	Main,
	Footer,
	GlobalContainer,
} from "../../styles/GlobalStyles";
import {
	Navbar,
	Footer as FooterComponent,
	Exam,
} from "../../components/Components";
import { useHistory } from "react-router-dom";

const PageExam = () => {
	const history = useHistory();
	const { examId, parentId, typeExam } = useExamContext();
	const [exam, setExam] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const loadExam = async () => {
			try {
				const { data } = await getExams(examId || "");
				setExam(data || {});
				setIsLoading(false);
			} catch (err) {
				console.error(err);
			}
		};
		loadExam();
	}, [examId]);

	return (
		<PageContainer>
			<Header>
				<Navbar />
			</Header>
			<Main>
				<GlobalContainer style={{ marginTop: "60px" }}>
					{!isLoading && (
						<>
							{exam && parentId ? (
								<Exam exam={exam} parentId={parentId} typeExam={typeExam} redirectUrl={history.location.pathname} />
							) : (
									<NoExam>Prova indisponível no momento.</NoExam>
								)}
						</>
					)}
				</GlobalContainer>
			</Main>
			<Footer>
				<FooterComponent />
			</Footer>
		</PageContainer>
	);
};

export default PageExam;
