import styled from "styled-components";

export const Container = styled.button`
  margin: 20px auto 0 0;
  padding: 9px 15px;
  display: inline-block;
  border: 1px solid #005713;
  border-radius: 8px;
  background-color: ${({ isWatched }) =>
		isWatched ? "#005713" : "transparent"};
  color: ${({ isWatched }) => (isWatched ? "#fff" : " #005713")};
  font-size: 14px;
  line-height: inherit;
  text-decoration: none;
	pointer-events: ${({ isWatched }) => (isWatched ? "none" : "")};
  cursor: pointer;
  &:hover {
    background-color: ${({ isWatched }) =>
		isWatched ? "#005713" : "#005713"};
    color: ${({ isWatched }) => (isWatched ? "#fff" : "#fff")};
  }
`;