import React from "react";
import { stripTags } from "../../../utils/manipulateString";
import {
  Container,
  Image,
  Duration,
  Content,
  Tags,
  Tag,
  Title,
} from "./styles";
import Placeholder from "../../../assets/images/placeholder.jpg";

const CardTrails = ({ trail }) => {
  if (!trail) return null;

  return (
    <Container>
      <Image
        url={(trail.thumbnail && trail.thumbnail.urlPublica) || Placeholder}
      >
        <Duration>{trail.duration || "00:00"}</Duration>
      </Image>
      <Content>
        <Tags>
          {trail.categories &&
            trail.categories.map((category) => (
              <Tag key={`tag-card-courses-${category.id}`}>
                {category.title}
              </Tag>
            ))}
        </Tags>
        <Title dangerouslySetInnerHTML={{ __html: stripTags(trail.title) }} />
      </Content>
    </Container>
  );
};

export default CardTrails;
