export const snakeToPascalCase = (string) => {
  let newString = string.charAt(0).toUpperCase();
  for (let i = 1; i < string.length; i++) {
    if (string.charAt(i - 1) === "_" && string.charAt(i) !== "-") {
      newString += string.charAt(i).toUpperCase();
    } else if (string.charAt(i) !== "_") {
      newString += string.charAt(i);
    }
  }
  return newString;
};

export const capitalizeFirstLetter = (string) => {
  let newString = string.charAt(0).toUpperCase();

  for (let i = 1; i < string.length; i++) {
    if (string[i - 1] === " ") {
      newString += string[i].toUpperCase();
    } else {
      newString += string[i].toLowerCase();
    }
  }
  return newString;
};

export const normalizeString = (string) => {
  const newString = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return newString;
};

export const removeSpaces = (string) => {
  let newString = "";
  // eslint-disable-next-line
  for (let char of string) {
    if (char !== " ") newString += char;
  }
  return newString;
};

export const stripTags = (string) => {
  if (string) {
    return string.replace(/(<([^>]+)>)/gi, "");
  }
  return string;
};

export const splitFullname = (str) => {
  const pos = str.indexOf(" ");
  const firstname = str.substring(0, pos);
  const lastname = str.substring(pos + 1).trim();

  return { firstname, lastname };
};

export const nameFormat = (name) => {
  const arrayName = name.split(" ");
  const firstName = arrayName[0];
  const lastChar =
    name.length > 1
      ? arrayName[arrayName.length - 1].charAt(0).toUpperCase()
      : "";

  return `${firstName}${firstName ? ` ${lastChar}` : ""}.`;
};

export const formatNumber = (pos) => {
  if (pos && pos < 100) {
    return `0${pos}`.slice(-2);
  }
  return pos;
};

export const getFirstLetters = (string) => {
  if (string) {
    const splitedString = string.split(" ");
    return (
      ((splitedString[0] && splitedString[0].charAt(0)) || "") +
      ((splitedString[1] &&
        splitedString[splitedString.length - 1].charAt(0)) ||
        "")
    );
  }
  return string;
};
