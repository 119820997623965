import React, { useState } from "react";
import { formatNumber } from "../../../utils/manipulateString";
import {
  Container,
  Header,
  HeaderInfo,
  Title,
  Progress,
  Quantity,
  ProgressBar,
  Icon,
  Courses,
} from "./styles";
import { CardProfile } from "../../Components";
import { CardDivisor } from "../../Cards/CardProfile/styles";

const Status = ({ title, courses, totalCourses }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Container isVisible={isVisible}>
      <Header onClick={(e) => setIsVisible((prev) => !prev)}>
        <HeaderInfo>
          <Title>{title || ""}</Title>
          <Progress>
            <Quantity>{formatNumber(courses.length)}</Quantity>
            <ProgressBar progress={(courses.length * 100) / totalCourses} />
          </Progress>
        </HeaderInfo>
        <Icon className='fas fa-angle-down' />
      </Header>
      <Courses>
        {courses.map((course, index) => (
          <React.Fragment key={`profile-${title}-card-${course.id}`}>
            {index > 0 && <CardDivisor />}
            <CardProfile course={course} />
          </React.Fragment>
        ))}
      </Courses>
    </Container>
  );
};

export default Status;
