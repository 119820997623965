import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { Auth } from "aws-amplify";

export const checkTokenExpires = (remainingSeconds) => {
  const token = getToken();
  const interval = setInterval(() => {
    if (token) {
      const { exp } = jwtDecode(token);
      const now = Math.floor(new Date().getTime() / 1000);
      const time = exp - now;
      if (time <= remainingSeconds) {
        refreshToken();
        clearInterval(interval);
      }
    }
  }, 5000);
};

export const refreshToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (session && session.idToken) {
        setToken(null, session.idToken.jwtToken);
      }
    });
  } catch (err) {
    console.error(err);
  }
};

export const getToken = (key) => {
  return Cookie.get(key || process.env.REACT_APP_TOKEN);
};

export const setToken = (key, value) => {
  if (window.location.origin.includes("localhost")) {
    Cookie.set(key || process.env.REACT_APP_TOKEN, value);
  }

  Cookie.set(key || process.env.REACT_APP_TOKEN, value, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const getAuthSignTokens = () => {
  const cookies = getAllCookies();
  const authSignTokens = {};
  Object.keys(cookies)
    // eslint-disable-next-line
    .filter((key) => {
      if (key.includes("splited")) {
        return key;
      } else if (key.includes("amplify")) {
        authSignTokens[key] = cookies[key];
      }
    })
    .map((key) => {
      const splitedValue = cookies[key].split("&separator&");
      const newKey = splitedValue[0];
      const newValue = splitedValue[1];
      return (authSignTokens[newKey] = newValue);
    });
  return authSignTokens;
};

export const setTokensAtLocalStorage = (tokens) => {
  Object.keys(tokens).forEach((key) => {
    localStorage.setItem(`${key}`, `${tokens[key]}`);
  });
};

export const removeToken = (key) => {
  if (window.location.origin.includes("localhost")) {
    Cookie.remove(key);
  }
  Cookie.remove(key, { domain: process.env.REACT_APP_DOMAIN });
};

export const hasAuthSignToken = () => {
  return Cookie.get("auth-sign");
};

export const removeRelatedCookies = () => {
  const cookies = getAllCookies();
  Object.keys(cookies).forEach((key) => {
    if (
      key.includes("splitedKey") ||
      key.includes("amplify") ||
      key.includes("cognito") ||
      key === "auth-sign"
    ) {
      removeToken(key);
    }
  });
};

export const getAllCookies = () => {
  const pairs = document.cookie.split(";");
  const cookies = {};
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
  }
  return cookies;
};
