import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 70px;
  margin-bottom: 50px;
`;

export const ContainerExamLesson = styled.div`
  margin: auto 0 0;
  padding: 25px 15px;
  width: 100%;
  display: flex;
	flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color: #00985f;
  color: #fff;
	border-radius: 12px;
`;

export const Title = styled.p`
  font-size: 14px;
  text-align: left;
`;

export const Strong = styled.strong`
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
`;