import React, { useState, useEffect } from "react";
import { getPagesBySlug } from "../../services/endpoints/cms/pages";
import { updateBlockEndpoint } from "../../utils";
import { snakeToPascalCase } from "../../utils/manipulateString";
import { PageFactory, PageNotFound } from "../Pages";

const SLUG = "curso-interno";
const INTERNAL_BLOCK = "CourseInternal";
const ENDPOINT = "/lms/courses";

const PageCourseInternal = ({ match }) => {
  const [pageSlug] = useState(SLUG);
  const [slug] = useState(match.params.slug);
  const [page, setPage] = useState({});

  useEffect(() => {
    const loadPage = async () => {
      try {
        const { data } = await getPagesBySlug(pageSlug);
        data[0].pages_blocks = updateBlockEndpoint(
          data[0].pages_blocks,
          INTERNAL_BLOCK,
          `${ENDPOINT}?slug=${slug}`
        );

        setPage(data[0] || null);
      } catch (err) {
        console.error(err);
      }
    };
    loadPage();
  }, [pageSlug, slug]);

  return (
    <>
      {page ? (
        page.title && (
          <PageFactory pageName={snakeToPascalCase(page.title)} page={page} />
        )
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default PageCourseInternal;
