import React from "react";
import { Container, Divisor } from "./styles";
import LessonsQuantity from "./LessonsQuantity";
import Like from "./Like";
import Favorite from "./Favorite";

const CourseInfo = ({ course }) => {
  if (!course) return null;
  return (
    <Container>
      <LessonsQuantity value={(course.lessons && course.lessons.length) || 0} />
      <Divisor />
      <Like courseId={course.id} />
      <Divisor />
      <Favorite courseId={course.id} />
    </Container>
  );
};

export default CourseInfo;
