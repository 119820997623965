import React from "react";
import PropTypes from "prop-types";
import { normalizeString } from "../utils/manipulateString";
import * as Pages from "./Pages";
import { ComponentBase } from "../components/Components";

const PageFactory = ({ pageName, page }) => {
	const normalizedPageName = normalizeString(pageName); // Remove acentos, ç -> c, etc...
	const PageToRender = Pages[normalizedPageName] || Pages["PageDefault"];

	return (
		<>
			<PageToRender page={page}>
				<ComponentBase page={page} />
			</PageToRender>
		</>
	);
};

PageFactory.propTypes = {
	pageName: PropTypes.string.isRequired,
};

export default PageFactory;
