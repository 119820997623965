import React from "react";
import { Container } from "./styles";
import MenuDropdownLink from "./MenuDropdownLink";
import MenuLink from "./MenuLink";

const LeftMenu = ({ menu }) => {
  if (!menu) return null;
  return (
    <Container>
      {menu.map((item) =>
        item.menu_items.length ? (
          <MenuDropdownLink dropdown={item} key={`dropdownLink-${item.id}`} />
        ) : (
          <MenuLink link={item} key={`link-${item.id}`} />
        )
      )}
    </Container>
  );
};

export default LeftMenu;
