import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  @media screen and (min-width: 991px) {
    display: none;
  }
`;

export const Icon = styled.i`
  padding: 20px;
  color: #ffa100;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ isVisible }) =>
    isVisible ? "#0E0E0E" : "transparent"};
`;
