import React, { useState } from "react";
import { Container } from "./styles";
import MenuDropdownItem from "./MenuDropdownItem";
import MenuItem from "./MenuItem";
import { IconAngle, IconProfile } from "./styles";
import { nameFormat } from "../../../../../utils/manipulateString";
import { getCurrentUser } from "../../../../../utils";
import MenuMobileProfile from "./MenuMobileProfile";

const Menu = ({ menu, isVisible, profileMenu }) => {
	const [user] = useState(getCurrentUser());

	if (!menu) return null;
	return (
		<Container isVisible={isVisible}>

			{menu.map((item) =>
				item.menu_items.length ? (
					<MenuDropdownItem dropdown={item} key={`mobilemenu-${item.id}`} />
				) : (
						<MenuItem item={item} key={`mobilemenu-${item.id}`} />
					)
			)}

			<IconProfile className='far fa-user-circle' />
			{user && nameFormat(user.name)}
			<IconAngle className='fas fa-angle-down' />
			<MenuMobileProfile dropdown={profileMenu} />

		</Container>
	);
};

export default Menu;
