import styled from "styled-components";

export const Container = styled.div`
  padding: 18px 15px;
  width: 100%;
  position: static;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d348d;
`;

export const Logo = styled.div`
  width: 150px;
  height: 80px;
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Divisor = styled.div`
  margin: 0 40px;
  width: 1px;
  height: 50px;
  border: 0px solid transparent;
  background-color: #f8f8f8;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Text = styled.p`
  margin-bottom: 8px;
  color: #fff;
  font-size: 13px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;
