import React from "react";
import { Row, Col } from "reactstrap";
import { Container, Content } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import { CardCourses, LinkHandler } from "../Components";

const Courses = ({ content }) => {
	const courses = content;

	if (!content || !content.length) return null;
	return (
		<>
			<Container>
				<Content>
					<Row className="justify-content-between">
						<PageTitle>Cursos</PageTitle>
					</Row>
					{courses.length ? (
						<Row style={{ marginBottom: "46px" }}>
							{courses.map((course) => (
								<Col sm='12' lg='4' key={`courses-card-${course.slug}`}>
									<LinkHandler url={`/curso-interno/${course.slug}`}>
										<CardCourses course={course} />
									</LinkHandler>
								</Col>
							))}
						</Row>
					) : (
							<p>Não possui nenhum registro</p>
						)}
				</Content>
			</Container>
		</>
	);
};

export default Courses;
