import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;

    & > * {
      margin: 10px 0;
    }
  }
`;

export const Thumbnail = styled.div`
  width: 100px;
  height: 60px;
  border-radius: 16px;
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
`;

export const Title = styled.p`
  color: #333;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const CardDivisor = styled.div`
  margin: 15px 0;
  width: 100%;
  height: 1px;
  border: 1px solid #e6e6e6;
`;
