import React, { useState, useEffect, useCallback } from "react";
import {
  getLikeByCourseAndUserId,
  updateLikes,
} from "../../../../services/endpoints/general/evaluations";
import { getCurrentUser } from "../../../../utils";
import { Info, Icon, Span } from "../styles";

const Like = ({ courseId }) => {
  const [courseLike, setCourseLike] = useState([]);

  const loadLikeCourse = useCallback(async () => {
    try {
      const { data } = await getLikeByCourseAndUserId(
        getCurrentUser().id,
        courseId
      );
      setCourseLike(data);
    } catch (err) {
      console.error(err);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      loadLikeCourse();
    }
  }, [courseId, loadLikeCourse]);

  const updateLike = async (event) => {
    event.preventDefault();

    const body = {
      user_iri: `/users/${getCurrentUser().id}`,
      content_iri: `/lms/courses/${courseId}`,
      value: "1",
      type: "LIKE",
    };

    try {
      await updateLikes(body);
      if (courseLike.length === 0) {
        setCourseLike((courseLike) => [...courseLike, 1]);
      } else {
        setCourseLike([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeLastClassName = (event) => {
    event.preventDefault();
    const classList = event.target.className.split(" ");
    if (classList[classList.length - 1] === "fas") {
      classList.pop();
    }

    event.target.className = classList.join(" ");
  };

  return (
    <Info>
      <Icon
        className={`${courseLike.length ? "fas" : "far"} fa-thumbs-up`}
        onClick={(e) => updateLike(e)}
        onMouseOver={(e) => e.target.classList.add("fas")}
        onMouseLeave={(e) => removeLastClassName(e)}
      />
      <Span>Curtir</Span>
    </Info>
  );
};

export default Like;
