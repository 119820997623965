import React from "react";
import { PageContainer } from "../../styles/GlobalStyles";
import { Container, Title, Icon } from "./styles";
import { Navbar, Footer } from "../../components/Components";

const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <PageContainer>
        <Container>
          <Title>Página não encontrada</Title>
          <Icon className='far fa-frown-open' />
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default PageNotFound;
