import React from "react";
import Routes from "./routes";
import GlobalStyles from "./styles/GlobalStyles";
import { RefreshToken } from "./components/Components";
import { ExamProvider } from "./contexts/Contexts";

function App() {
	return (
		<>
			<ExamProvider>
				<Routes />
			</ExamProvider>
			<RefreshToken />
			<GlobalStyles />
		</>
	);
}

export default App;
