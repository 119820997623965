import styled from "styled-components";

export const Container = styled.div`
  padding: 15%;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  margin: 10px;
`;

export const Icon = styled.i`
  padding: 5px 0 0;
  font-size: 30px;
`;
