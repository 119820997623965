import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Container, Title, Strong, ContainerExamLesson } from "./styles";
import Lesson from "./Lesson";
import CourseInfo from "./CourseInfo";
import SupportMaterials from "./SupportMaterials";
import Notes from "./Notes";
import OtherLessons from "./OtherLessons";
import CongratsMessage from "./CongratsMessage";
import CongratsMessageLesson from "./CongratsMessageLesson";
import { getExamLessonApproval } from "../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../utils";
import { getCourses } from "../../services/endpoints/lms/courses";

const CourseInternal = ({ content, refreshCourse, setRefreshCourse }) => {
    const [course, setCourse] = useState(null);
    const [currentLessonPosition, setCurrentLessonPosition] = useState(0);
    const [currentLesson, setCurrentLesson] = useState();
    const [lessonStats, setLessonStats] = useState();
    const [lessonExams, setLessonExams] = useState();
    const [courseStats, setCourseStats] = useState();
    const [courseExam, setCourseExam] = useState();
    const [examUnlock, setExamUnlock] = useState(false);
    const [internalRefreshCourse, setInternalRefreshCourse] = useState(refreshCourse || false);

    useEffect(() => {
        if (content && content[0]) {
            setCourse(content[0]);
            setCourseStats(
                (content[0].course_stats &&
                    content[0].course_stats[0] &&
                    content[0].course_stats[0]) ||
                null
            );
            setCourseExam(
                (content[0].course_exams &&
                    content[0].course_exams[0] &&
                    content[0].course_exams[0].exam) ||
                null
            );
        }
    }, [content, refreshCourse, internalRefreshCourse]);

    useEffect(() => {
        const loadCourses = async () => {
            try {
                const { data } = await getCourses({ id: content[0].id });
                setCourse(data[0] || null);
                setCourseStats(
                    (data[0].course_stats &&
                        data[0].course_stats[0] &&
                        data[0].course_stats[0]) || null
                );
            } catch (err) {
                console.error(err);
            }
        };

        if (internalRefreshCourse) {
            loadCourses();
        }
    }, [internalRefreshCourse]);

    useEffect(() => {
        if (course && course.lessons) {
            setCurrentLesson(course.lessons[currentLessonPosition]);
        }
    }, [course, currentLessonPosition, internalRefreshCourse]);

    useEffect(() => {
        if (currentLesson) {
            setLessonStats(
                (currentLesson.lesson_stats &&
                    currentLesson.lesson_stats[0] &&
                    currentLesson.lesson_stats[0]) || null
            );

            setLessonExams(
                (currentLesson.lesson_exams &&
                    currentLesson.lesson_exams.length > 0 &&
                    currentLesson.lesson_exams) ||
                null
            );
        }
    }, [currentLesson, refreshCourse, internalRefreshCourse]);

    useEffect(() => {

        const checkExam = async () => {
            if (currentLesson) {
                setExamUnlock(false);
                const lastLesson =
                    (
                        content[0].lessons &&
                            content[0].lessons.length ?
                            content[0].lessons[content[0].lessons.length - 1] : null
                    );

                const existExamsLesson = (
                    lastLesson &&
                        lastLesson.lesson_exams &&
                        lastLesson.lesson_exams.length ?
                        lastLesson.lesson_exams :
                        null
                );

                let resultExamsLesson = null;
                if (existExamsLesson) {
                    const resultsExams = await Promise.all(existExamsLesson.map(async (lessonExam) => await checkExamLesson(lessonExam, lastLesson)));
                    resultExamsLesson = resultsExams.every((res) => !!res);
                } else {
                    resultExamsLesson = true;
                }

                setExamUnlock(resultExamsLesson);
            }
        }

        checkExam();
    }, [currentLesson, examUnlock, refreshCourse, internalRefreshCourse]);

    const checkExamLesson = async (lessonExam, lesson) => {

        const resultExam = await getResultExam(lesson.id, lessonExam.exam);
        if (!resultExam) {
            return false;
        }

        if (resultExam && resultExam.length && lessonExam.exam.mandatory_approval && resultExam[0].approved) {
            return true;
        }

        if (!lessonExam.exam.mandatory_approval && resultExam && resultExam.length) {
            return true;
        }

    }

    const getResultExam = async (lessonId, exam) => {
        try {
            const { data } = await getExamLessonApproval(getCurrentUser().id, lessonId, exam.id);
            return (data && data.length ? data : null);
        } catch (e) {
            console.error("CourseInternal getExam: ", e);
        }
    }

    const checkedExamUnlock = async () => {
        const lastLesson =
            (
                content[0].lessons &&
                    content[0].lessons.length ?
                    content[0].lessons[content[0].lessons.length - 1] : null
            );


        const existExamsLesson = (
            lastLesson &&
                lastLesson.lesson_exams &&
                lastLesson.lesson_exams.length ?
                lastLesson.lesson_exams :
                null
        );

        let resultExamsLesson = null;
        if (existExamsLesson) {
            const resultsExams = await Promise.all(existExamsLesson.map(async (lessonExam) => await checkExamLesson(lessonExam, lastLesson)));
            resultExamsLesson = resultsExams.every((res) => !!res);
        } else {
            resultExamsLesson = true;
        }

        return resultExamsLesson;

    }

    if (!course) return null;
    return (
        <>
            <Container>
                <Row>
                    <Col xs='12' lg='9'>
                        {currentLesson && <Lesson setRefreshCourse={(setRefreshCourse ? setRefreshCourse : setInternalRefreshCourse)} lesson={currentLesson} />}
                    </Col>
                    <Col xs='12' lg='3'>
                        <CourseInfo course={course} />
                    </Col>
                </Row>

                {currentLesson && (
                    <SupportMaterials
                        supportMaterials={currentLesson.lesson_support_materials}
                    />
                )}

                {currentLesson && lessonStats && lessonStats.lesson_status === "CONCLUIDO" && lessonExams &&

                    <ContainerExamLesson>
                        <Title>
                            <Strong>Atividades</Strong>
                        </Title>
                        <Row>
                            {lessonExams.map((lessonExam) => (
                                <Col key={`lesson-exam-${lessonExam.exam.id}`} xs="4" sm="2">
                                    <CongratsMessageLesson nameExam={lessonExam.exam.title}
                                        exam={lessonExam.exam} parentId={currentLesson.id} />
                                </Col>
                            ))
                            }
                        </Row>
                    </ContainerExamLesson>
                }

                <Notes currentLessonId={course.lessons[currentLessonPosition].id} />

                {course.lessons && (
                    <OtherLessons
                        refreshCourse={internalRefreshCourse}
                        lessons={course.lessons}
                        currentLessonPosition={currentLessonPosition}
                        setCurrentLessonPosition={setCurrentLessonPosition}
                    />
                )}
            </Container>
            {courseStats && courseStats.course_status === "CONCLUIDO" && examUnlock && courseExam && (
                <CongratsMessage exam={courseExam} courseStats={courseStats} parentId={course.id} />
            )}
        </>
    );
};

export default CourseInternal;
