import React from "react";
import { Container } from "./styles";
import { ContentMedia } from "../../../Components";

const ActualContent = ({ content, setRefreshCourse }) => {
    if (!content) return null;
    return (
        <Container>
            <ContentMedia setRefreshCourse={setRefreshCourse} content={content} />
        </Container>
    );
};

export default ActualContent;
