import api from "../../api";

export const getUsers = (params = {}) =>
  api.get("/users", {
    params: {
      ...params,
    },
  });

export const getUsersById = (id, params = {}) =>
  api.get("/users", {
    params: {
      id,
      ...params,
    },
  });

export const getMe = (params = {}) =>
  api.get("/users/me", {
    params: {
      ...params,
    },
  });
