import React, { useState, useEffect, useCallback } from "react";
import {
  getFavoriteByCourseAndUserId,
  updateFavorites,
} from "../../../../services/endpoints/general/evaluations";
import { getCurrentUser } from "../../../../utils";
import { Info, Icon, Span } from "../styles.js";

const Favorite = ({ courseId }) => {
  const [favoriteCourse, setFavoriteCourse] = useState([]);

  const loadFavoriteCourse = useCallback(async () => {
    try {
      const { data } = await getFavoriteByCourseAndUserId(
        getCurrentUser().id,
        courseId
      );
      setFavoriteCourse(data);
    } catch (err) {
      console.error(err);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      loadFavoriteCourse();
    }
  }, [courseId, loadFavoriteCourse]);

  const updateFavorite = async (event) => {
    event.preventDefault();

    const body = {
      user_iri: `/users/${getCurrentUser().id}`,
      content_iri: `/lms/courses/${courseId}`,
      value: "1",
      type: "FAVORITE",
    };

    try {
      await updateFavorites(body);
      if (favoriteCourse.length === 0) {
        setFavoriteCourse((favoriteCourse) => [...favoriteCourse, 1]);
      } else {
        setFavoriteCourse([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeLastClassName = (event) => {
    event.preventDefault();
    const classList = event.target.className.split(" ");
    if (classList[classList.length - 1] === "fas") {
      classList.pop();
    }

    event.target.className = classList.join(" ");
  };

  return (
    <Info>
      <Icon
        className={`${favoriteCourse.length ? "fas" : "far"} fa-star`}
        onClick={(e) => updateFavorite(e)}
        onMouseOver={(e) => e.target.classList.add("fas")}
        onMouseLeave={(e) => removeLastClassName(e)}
      />
      <Span>Adc Favoritos</Span>
    </Info>
  );
};

export default Favorite;
