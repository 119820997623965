import styled from "styled-components";

export const Container = styled.div`
  width: 120px;
  height: 100%;
  position: relative;
  cursor: pointer;
  & > div {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover > div,
  &:hover i {
    color: var(--primary-color);
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const Link = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconProfile = styled.i`
  padding: 0 5px 0 0;
  color: #333;
  font-size: 14px;
  line-height: 1px;
  text-align: center;
`;

export const IconAngle = styled.i`
  padding: 0 0 0 5px;
  color: #333;
  font-size: 13px;
  line-height: 1px;
  text-align: center;
`;

export const Dropdown = styled.div`
  min-width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;

  ${Container}:hover & {
    display: flex;
  }
`;
