import React, { useState } from "react";
import axios from "axios";
import { getToken } from "../../../services/auth";
import { stripTags } from "../../../utils/manipulateString";
import {
  Container,
  Image,
  ImageIcon,
  Duration,
  Content,
  Type,
  Title,
  Span,
  ProgressBar,
  Button,
  ButtonIcon,
} from "./styles";
import Placeholder from "../../../assets/images/placeholder.jpg";

const CardCertificates = ({ certificate }) => {
  const [course] = useState((certificate && certificate.course) || {});

  const handleCertificateClick = (event) => {
    event.preventDefault();
    axios({
      url: `${process.env.REACT_APP_API_URL}/lms/courses/${course.id}/certificate_issuance`,
      method: "GET",
      responseType: "arraybuffer", // important
      headers: { Authorization: getToken() },
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
    });
  };

  if (!certificate) return null;
  return (
    <Container>
      <Image
        url={
          (course.image && certificate.course.image.urlPublica) || Placeholder
        }
      >
        <ImageIcon className='fas fa-award' />
        <Duration>{course.duration || "00:00"}</Duration>
      </Image>
      <Content>
        <Type>{"curso" || ""}</Type>
        <Title dangerouslySetInnerHTML={{ __html: stripTags(course.title) }} />
        <Span>
          Progresso <ProgressBar /> 100%
        </Span>
        <Span>
          {"Conclusão: "}
          {(certificate.completion_at &&
            certificate.completion_at.split(" ")[0]) ||
            ""}
        </Span>
        <Span>Tentativas: {certificate.attempts || ""}</Span>
        <Span>Nota máxima: {certificate.max_grade || ""}</Span>
        <Button onClick={(e) => handleCertificateClick(e)}>
          Baixar
          <ButtonIcon className='fas fa-download' />
        </Button>
      </Content>
    </Container>
  );
};

export default CardCertificates;
