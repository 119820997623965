import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { redirectToLogout } from "./services/auth";
import { getMe } from "./services/endpoints/users";
import {
	PageBase,
	PageCourseInternal,
	PageTrailInternal,
	PageExam,
	PageSearch,
} from "./pages/Pages";
import Courses from "./pages/Courses";
import PageTrails from "./pages/PageTrails";
import PageCertificates from "./pages/PageCertificates";

const requireLogin = async (to, from, next) => {
	try {
		const { data } = await getMe();
		localStorage.setItem(`zaraplast.user`, JSON.stringify(data));
		next();
	} catch (err) {
		console.error(err);
		redirectToLogout();
	}
	next.redirect("/");
};

const Routes = () => (
	<Router>
		<GuardProvider guards={[requireLogin]}>
			<Switch>
				<GuardedRoute exact path='/' component={PageBase} />
				<GuardedRoute
					exact
					path='/curso-interno/:slug'
					component={PageCourseInternal}
				/>

				<GuardedRoute
					exact
					path='/curso-interno/:slug/prova'
					component={PageExam}
				/>

				<GuardedRoute
					exact
					path='/trilha-interna/:slug/prova'
					component={PageExam}
				/>

				<GuardedRoute
					exact
					path='/trilha-interna/:slug'
					component={PageTrailInternal}
				/>

				<GuardedRoute exact path='/search/:slug' component={PageSearch} />

				<GuardedRoute path='/cursos' component={Courses} />

				<GuardedRoute path='/trilhas' component={PageTrails} />

				<GuardedRoute path='/certificados' component={PageCertificates} />

				<GuardedRoute exact path='/:slug' component={PageBase} />
			</Switch>
		</GuardProvider>
	</Router>
);

export default Routes;
