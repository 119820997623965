import React from "react";
import PropTypes from "prop-types";
import * as Components from "./Components";

const ComponentFactory = ({ componentName, content, block }) => {
  const ComponentToRender = Components[componentName] || (() => <></>);

  return <ComponentToRender content={content} block={block} />;
};

ComponentFactory.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default ComponentFactory;
