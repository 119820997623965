import React, { useState } from "react";
import { stripTags } from "../../../utils/manipulateString";
import { Container, Header, Icon, Question, Answer } from "./styles";

const FaqItem = ({ faq }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Container isVisible={isVisible}>
      <Header onClick={(e) => setIsVisible((prev) => !prev)}>
        <Question
          dangerouslySetInnerHTML={{ __html: stripTags(faq.question) }}
        />
        <Icon isVisible={isVisible} className='fas fa-angle-down fa-fw' />
      </Header>
      <Answer dangerouslySetInnerHTML={{ __html: stripTags(faq.answer) }} />
    </Container>
  );
};

export default FaqItem;
