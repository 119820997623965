import React, { useEffect } from "react";
import PropTypes from "prop-types";
import api from "../../../services/api";

const ImageFile = (props) => {
  const { src, alt, id, updateStats } = props;

  useEffect(() => {
    api
      .post(`/general/watcheds`, {
        user_iri: atob(localStorage.getItem(`user-endpoint`)),
        progress: 0,
        content_iri: `/lms/lesson_contents/${id}`,
        watched: false,
      })
      .then((resp) => {
        updateStats();
      });

    setTimeout(() => {
      api
        .post(`/general/watcheds`, {
          user_iri: atob(localStorage.getItem(`user-endpoint`)),
          progress: 100,
          content_iri: `/lms/lesson_contents/${id}`,
          watched: true,
        })
        .then((resp) => {
          updateStats();
        });
    }, 3000);
  }, [src, alt, id, updateStats]);

  return <img className='img-fluid' src={src} alt={alt} />;
};

ImageFile.propTypes = {
  url: PropTypes.string.isRequired,
};
export default ImageFile;
