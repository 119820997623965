import React, { useEffect, useState } from 'react';
import { ContainerModal, ModalBody, LabelClose } from './styles';
import Success from '../../assets/jsons/animations/check-animation-green.json';
import Fail from '../../assets/jsons/animations/error-cancelled.json';
import Lottie from 'react-lottie';

function Modal({ children, open, toggle, typeMessage }) {
	const [isOpen, setIsOpen] = useState(false);

	const options_success = {
		loop: true,
		autoplay: true,
		animationData: Success,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}

	const options_fail = {
		loop: true,
		autoplay: true,
		animationData: Fail,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<ContainerModal open={isOpen}>
			<ModalBody>
				<LabelClose onClick={toggle}></LabelClose>
				{typeMessage === "success" ?
					(
						<Lottie
							height={150}
							width={200}
							options={options_success} />
					) :
					(
						<Lottie
							height={150}
							width={200}
							options={options_fail} />
					)
				}
				{children}
			</ModalBody>
		</ContainerModal>
	);
}

export default Modal;