import styled from "styled-components";

export const Container = styled.div`
  margin-top: 35px;
  width: 100%;
  height: 267px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 8px 8px 16px 16px;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  cursor: pointer;
  transition: box-shadow 400ms ease, transform 400ms ease;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transform: translate(0px, -6px) scale(1.02);
  }
`;

export const ThumbnailVideo = styled.div`
  height: 120px;
  position: relative;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      rgba(29, 28, 28, 0.6) 50%
    ),
    url(${({ url }) => url});
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
`;

export const ThumbnailFile = styled.div`
  height: 120px;
  padding: 17px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid transparent;
  background-color: #2d348d;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: right;
`;

export const Duration = styled.span`
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: #aca3a3;
  font-size: 14px;
  text-align: right;
  text-decoration: none;
`;

export const Content = styled.div`
  width: 100%;
  height: 147px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21px;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;

  &.current{
    background-color: #051b83;
    color: white;
  }
`;

export const Image = styled.div`
  height: 200px;
  position: relative;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      rgba(29, 28, 28, 0.6) 50%
    ),
    url(${({ url }) => url});
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
`;

export const Title = styled.h5`
  width: 100%;
`;
