import styled from "styled-components";

export const Container = styled.div`
  margin-top: 35px;
  width: 100%;
  height: 330px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 8px 8px 16px 16px;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  cursor: pointer;
  transition: box-shadow 400ms ease, transform 400ms ease;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transform: translate(0px, -6px) scale(1.02);
  }
`;

export const Image = styled.div`
  height: 200px;
  position: relative;
  border: 0px solid transparent;
  border-radius: 8px 8px 0px 0px;
  background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      rgba(29, 28, 28, 0.6) 50%
    ),
    url(${({ url }) => url});
  background-position: 0px 0px, 50% 10%;
  background-size: auto, cover;
`;

export const Duration = styled.span`
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: #aca3a3;
  font-size: 14px;
  text-align: right;
  text-decoration: none;
`;

export const Content = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21px;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;

  &.current{
    background-color: #051b83;
    color: white;
  }
`;

export const Tags = styled.div`
  margin: 0 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Tag = styled.span`
  margin: 0 10px 0 0;
  color: #bdbec3;
  font-size: 10px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
`;

export const Title = styled.h5`
  width: 100%;
  color: #333;
  font-size: 17px;
  line-height: 25px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;

  &.current{
    color: white;
  }
`;
