import React, { useEffect, useState } from 'react';
import { getPagesBySlug } from '../../services/endpoints/cms/pages';
import { Row, Col, Spinner } from "reactstrap";
import { Container, Content } from "../../components/Trails/styles";
import { PageTitle, Button } from "../../styles/GlobalStyles";
import { CardTrails, LinkHandler, ComponentBase } from "../../components/Components";
import { getTrails } from '../../services/endpoints/lms/trails';

function PageTrails() {
    const perPage = 3;
    const [trails, setTrails] = useState();
    const [loading, setLoading] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState(perPage);
    const [page, setPage] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const response = await getPagesBySlug('trilhas')
                setPage(response.data[0] || null);
            } catch (err) {
                console.error(err);
            }
        })()
    }, []);

    useEffect(() => {
        const loadTrails = async () => {
            try {
                const { data } = await getTrails({ page: 0, perPage: currentPage });
                setTrails(data || null);
                setLoading(false);
                setShowSpinner(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
                setShowSpinner(false);
            }
        };
        loadTrails();
    }, [currentPage, perPage]);

    const handlePagination = () => {
        setShowSpinner(true);
        setCurrentPage(prevValue => prevValue + perPage);
    }

    if (loading) { return (<div className="text-center"><Spinner className="m-5" color="primary" /></div>) }
    return (
        <ComponentBase page={page}>
            <Container className="mt-5">
                <Content>
                    <PageTitle>{page.title}</PageTitle>
                    {trails && trails.length ? (
                        < Row style={{ marginBottom: "46px" }}>
                            {trails.map((trail) => (
                                <Col sm='12' lg='4' key={`trails-card-${trail.id}`}>
                                    <LinkHandler url={`/trilha-interna/${trail.id}`}>
                                        <CardTrails trail={trail} />
                                    </LinkHandler>
                                </Col>
                            ))}
                        </Row>
                    ) :
                        (
                            < Row style={{ marginBottom: "67px" }}>

                                <p>Não possui nenhum registro</p>

                            </Row>
                        )
                    }
                </Content>
                {showSpinner && <div className="text-center"><Spinner className="m-5" color="primary" /></div>}
                {trails && trails.length > 0 && trails.length >= currentPage &&
                    <div className="text-center">
                        <Button onClick={handlePagination}>Carregar mais</Button>
                    </div>
                }
            </Container >
        </ComponentBase>
    );
}

export default PageTrails;