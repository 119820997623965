import styled from "styled-components";

export const Container = styled.div`
  margin-top: 35px;
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  cursor: pointer;
  transition: box-shadow 400ms ease, transform 400ms ease;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transform: translate(0px, -6px) scale(1.02);
  }
`;

export const Image = styled.div`
  height: 190px;
  position: relative;
  border: 0px solid transparent;
  border-radius: 16px 16px 0px 0px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.57),
      rgba(0, 0, 0, 0.57)
    ),
    url(${({ url }) => url});
  background-position: 0px 0px, 50% 25%;
  background-size: auto, cover;
`;

export const ImageIcon = styled.i`
  position: absolute;
  top: 40%;
  left: 45%;
  color: #bdbec3;
  font-size: 50px;
  text-align: center;
`;

export const Duration = styled.span`
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: #aca3a3;
  font-size: 14px;
  text-align: right;
  text-decoration: none;
`;

export const Content = styled.div`
  width: 100%;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 21px;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
`;

export const Type = styled.span`
  color: #616161;
  font-size: 11px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
`;

export const Title = styled.h5`
  margin: 3px 0 20px;
  font-weight: bold;
  font-size: 20px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 20px;
  color: #333;
`;

export const Span = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #616161;
  font-size: 14px;
  line-height: 20px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;

export const ProgressBar = styled.div`
  width: 100px;
  height: 5px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 20px;
  background-color: #00985f;
`;

export const Button = styled.button`
  margin: 15px 0 10px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ff0000;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;

export const ButtonIcon = styled.i`
  padding: 0 10px;
`;
