import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Container, Content } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import { CardCertificates } from "../Components";
import { getExamApproval } from "../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../utils";

const Certificates = ({ content }) => {
	const [certificates] = useState(content || []);
	const [isApproved, setIsApproved] = useState(false);

	const hasMandatoryExam = (course) => {
		if (
			course &&
			course.course_exams &&
			course.course_exams[0] &&
			course.course_exams[0].exam &&
			course.course_exams[0].exam.mandatory_approval
		) {
			verifyApproval(course.id);
			return true;
		}
		return false;
	};

	const verifyApproval = async (courseId) => {
		try {
			const { data } = await getExamApproval(getCurrentUser().id, courseId);
			setIsApproved(data[0].approved);
		} catch (err) {
			console.error(err);
		}
	};

	if (!content || !content.length) return null;
	return (
		<Container>
			<Content>
				<Row className="justify-content-between">
					<PageTitle>Certificados</PageTitle>
				</Row>
				{certificates && certificates.length ?
					(
						<Row style={{ marginBottom: "46px" }}>
							{certificates.map((certificate) =>
								hasMandatoryExam(certificate.course) ? (
									isApproved && (
										<Col sm='12' lg='4' key={`certificate-card-${certificate.id}`}>
											<CardCertificates certificate={certificate} />
										</Col>
									)
								) : (
										<Col sm='12' lg='4' key={`certificate-card-${certificate.id}`}>
											<CardCertificates certificate={certificate} />
										</Col>
									)
							)}
						</Row>
					)
					:
					<Row style={{ marginBottom: "277px" }}>
						<p>Não existe registro de certificados</p>
					</Row>
				}
			</Content>
		</Container>
	);
};

export default Certificates;
