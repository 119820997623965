import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import { CourseInternal } from "../Components";
import OtherCourses from "./OtherCourses";
import { getTrails } from "../../services/endpoints/lms/trails";

const TrailInternal = ({ content }) => {
    const [trail, setTrail] = useState((content && content[0]) || []);
    const [currentCoursePosition, setCurrentCoursePosition] = useState(0);
    const [currentCourse, setCurrentCourse] = useState(null);
    const [refreshCourse, setRefreshCourse] = useState(null);

    useEffect(() => {
        const loadTrails = async () => {
            const { data } = await getTrails({ id: content[0].id });
            setTrail(data ? data[0] : content);
        }

        if (refreshCourse) {
            loadTrails();
        }
    }, [refreshCourse, content]);

    useEffect(() => {
        setCurrentCourse(
            trail.trails_courses &&
            trail.trails_courses[currentCoursePosition] &&
            trail.trails_courses[currentCoursePosition].course
        )
    }, [trail, currentCoursePosition]);

    return (
        <Container>
            {currentCourse && <CourseInternal refreshCourse={refreshCourse} setRefreshCourse={setRefreshCourse} content={[currentCourse]} />}
            <OtherCourses
                trail={trail}
                setCurrentCoursePosition={setCurrentCoursePosition}
                currentCoursePosition={currentCoursePosition}
            />
        </Container>
    );
};

export default TrailInternal;
