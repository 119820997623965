import React, { useState, useEffect } from "react";
import {
	PageContainer,
	Header,
	Main,
	Footer,
	PageTitle,
	GlobalContainer,
} from "../../styles/GlobalStyles";
import {
	Navbar,
	Footer as FooterComponent,
	Courses, Trails
} from "../../components/Components";
import { getDataSearchByKeyword } from "../../services/endpoints/lms/search";

const PageSearch = ({ match }) => {
	const [keyword] = useState(match.params.slug);
	const [dataSearch, setDataSearch] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const loadDataSearch = async () => {
			try {
				const { data } = await getDataSearchByKeyword(keyword);
				setDataSearch(data || []);
				setIsLoading(false);
			} catch (err) {
				console.error(err);
			}
		};
		setIsLoading(true);
		loadDataSearch();
	}, [keyword]);

	return (
		<PageContainer>
			<Header>
				<Navbar />
			</Header>
			<Main>
				{!isLoading && (
					<>
						{
							(dataSearch.courses && dataSearch.courses.length) ||
								(dataSearch.trails && dataSearch.trails.length) ? (
									<>
										{
											dataSearch.courses && dataSearch.courses.length &&
											<Courses content={dataSearch.courses} />
										}

										{
											dataSearch.trails && dataSearch.trails.length &&
											<Trails content={dataSearch.trails} />
										}
									</>
								) : (
									<GlobalContainer>
										<PageTitle style={{ margin: "70px 0 0" }}>
											Nenhum registro encontrado
                </PageTitle>
									</GlobalContainer>
								)
						}
					</>
				)
				}
			</Main >
			<Footer>
				<FooterComponent />
			</Footer>
		</PageContainer >
	);
};

export default PageSearch;
