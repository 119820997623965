import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import LeftMenu from "./LeftMenu";
import Search from "./Search";
import ProfileMenu from "./ProfileMenu";
import MobileMenu from "./MobileMenu";
import { getMenusById } from "../../../services/endpoints/cms/menus";

const LEFT_MENU_ID = 8;
const PROFILE_MENU_ID = 9;

const Menu = () => {
	const [leftMenu, setLeftMenu] = useState([]);
	const [profileMenu, setProfileMenu] = useState([]);

	useEffect(() => {
		const loadData = async () => {
			try {
				const leftMenuResponse = await getMenusById(LEFT_MENU_ID);
				const profileMenuResponse = await getMenusById(PROFILE_MENU_ID);
				setLeftMenu(leftMenuResponse.data[0].menu_items || []);
				setProfileMenu(profileMenuResponse.data[0].menu_items || []);
			} catch (err) {
				console.error(err);
			}
		};
		loadData();
	}, []);

	return (
		<Container>
			<LeftMenu menu={leftMenu} />
			<Search />
			<ProfileMenu menu={profileMenu} />
			<MobileMenu menu={[...leftMenu]} profileMenu={profileMenu} />
		</Container>
	);
};

export default Menu;
