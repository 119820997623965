import React from "react";
import { Info, Value, Span } from "../styles";
import { formatNumber } from "../../../../utils/manipulateString";

const LessonsQuantity = ({ value }) => {
  return (
    <Info>
      <Value>{formatNumber(value || "")}</Value>
      <Span>Aulas</Span>
    </Info>
  );
};

export default LessonsQuantity;
