import React, { useState, useEffect, useCallback } from "react";
import { Container, Content } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import { CardFavorites } from "../Components";
import { CardDivisor } from "../Cards/CardFavorites/styles";
import { getFavorites } from "../../services/endpoints/general/evaluations";

const Favorites = ({ content }) => {
  const [favorites, setFavorites] = useState(content || []);
  const [reload, setReload] = useState(false);

  const loadFavorites = useCallback(async () => {
    try {
      const { data } = await getFavorites();
      setFavorites(data || []);
    } catch (err) {
      console.error(err);
    }
  }, [setFavorites]);

  useEffect(() => {
    if (reload) {
      loadFavorites();
      setReload(false);
    }
  }, [reload, loadFavorites]);

  if (!content) return null;
  return (
    <Container>
      <PageTitle>Favoritos</PageTitle>
      <Content>
        {favorites.map((favorite, index) => (
          <React.Fragment key={`favorite-${favorite}-card-${favorite.id}`}>
            {index > 0 && <CardDivisor />}
            {favorite.content && (
              <CardFavorites
                favorite={favorite.content}
                setReload={setReload}
              />
            )}
          </React.Fragment>
        ))}
      </Content>
    </Container>
  );
};

export default Favorites;
