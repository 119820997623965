import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: block;
  & a {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover a {
    color: var(--primary-color);
  }
`;
