import React, { useState } from "react";
import { Container, Banner, Info, Title, Description } from "./styles";
import { CustomSlider, LinkHandler } from "../Components";
import Placeholder from "../../assets/images/placeholder.jpg";
import { stripTags } from "../../utils/manipulateString";
import { Button } from "../../styles/GlobalStyles";

const HomeBanners = ({ content }) => {
	const [banners] = useState((content && content.banners) || []);

	return (
		<Container>
			<CustomSlider
				content={banners.map((banner) => (
					<Banner
						url={(banner.file && banner.file.urlPublica) || Placeholder}
						urlMobile={(banner.file_mobile && banner.file_mobile.urlPublica) || Placeholder}
						key={`banner-slider-${banner.id}`}
					>
						<Info>
							<Title
								dangerouslySetInnerHTML={{ __html: stripTags(banner.title) }}
							/>
							<Description
								dangerouslySetInnerHTML={{
									__html: stripTags(banner.description),
								}}
							/>
							{banner.button_text ?
								<LinkHandler url={banner.url} target={banner.target}>
									<Button color='#fff'>{banner.button_text}</Button>
								</LinkHandler>
								:
								''
							}
						</Info>
					</Banner>
				))}
			/>
		</Container>
	);
};

export default HomeBanners;
