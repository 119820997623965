import styled from "styled-components";

export const Container = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Thumbnail = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #2d348d;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
`;
