import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;        
    }

    html, body, #root {
        height: 100%;
    }


    *, button, input {
        border: 0;
        outline: 0;
        font-family: Montserrat, sans-serif;
    }

    :root {
        --primary-color: #2D348D;
        --secondary-color: #fc4c02;
    }

`;

export const GlobalContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  @media (min-width: 480px) {
    max-width: 728px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const PageContainer = styled.div`
  margin: 60px 0 0;
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled.h2`
  margin: 0 0 60px;
  padding-left: 0px;
  position: relative;
  color: #333;
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

  &:after {
    content: "";
    padding-right: 0px;
    width: 100px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -15px;
    background-color: var(--primary-color);
  }
`;

export const Header = styled.header`
  width: 100%;
`;
export const Main = styled.main`
  width: 100%;
  flex: 1;
`;

export const Footer = styled.footer`
  width: 100%;
`;

export const Button = styled.button`
  padding: 12px 38px;
  display: inline-block;
  color: ${({ color }) => color || "var(--primary-color)"};
  background-color: transparent;
  border: 1px solid ${({ color }) => color || "var(--primary-color)"};
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 500ms ease;

  &:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: transparent;
    transform: translate(0px, -5px);
  }
`;
