import styled from "styled-components";

export const ContainerModal = styled.div`
	z-index: 1;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0,0,0, .9);
  transition: opacity .25s ease;
  ${({ open }) => open ? `visibility: visible` : `display: none`};
	opacity: 1;
`;

export const ModalBody = styled.div`
	transition: top .25s ease;
  position: absolute;
  top: -20%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  padding: 1em 2em;
  height: 50%;
`;


export const LabelClose = styled.label`
	
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;

	&:after,
	&:before {
		content: '';
		position: absolute;
		width: 2px;
		height: 1.5em;
		background: #ccc;
		display: block;
		transform: rotate(45deg);
		left: 50%;
		margin: -3px 0 0 -1px;
		top: 0;
	}

	&:hover:after,
	&:hover:before {
		background: #aaa;
	}

	&:before {
		transform: rotate(-45deg);
	}
`;
