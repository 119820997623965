import React from "react";
import { FileContainer, FileIcon, FileText } from "./styles";
import { updateWatcheds } from "../../../services/endpoints/general/watcheds";

const File = ({ content, contentIri, userIri, setRefreshCourse }) => {
    const handleClick = async (event) => {
        event.preventDefault();
        try {
            const url = content.file.urlPublica;
            if (window.open(url, "_blank")) {
                const body = {
                    user_iri: userIri,
                    progress: 0,
                    content_iri: contentIri,
                    watched: false,
                };
                await updateWatcheds(body);
                Object.assign(body, { progress: 100, watched: true });
                await updateWatcheds(body);

                if (setRefreshCourse) {
                    setRefreshCourse(false);
                    setRefreshCourse(true);
                }
            }

        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className='embed-responsive embed-responsive-16by9'>
            <FileContainer className='embed-responsive-item'>
                <FileIcon className='fas fa-file-download' />
                <FileText>
                    <strong onClick={(e) => handleClick(e)}>Clique aqui</strong>
                    {" realizar download"}
                </FileText>
            </FileContainer>
        </div>
    );
};

export default File;
