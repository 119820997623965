import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 55px;
`;

export const CardContent = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 55px 38px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 13px -8px #646262;
  cursor: pointer;
  transition: box-shadow 400ms ease, transform 400ms ease;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
    transform: translate(0px, -6px) scale(1.02);
  }
`;

export const Icon = styled.i`
  font-size: 35px;
  color: var(--primary-color);
`;

export const Title = styled.h3`
  color: #333;
  font-size: 23px;
  line-height: 30px;
  font-weight: 700;
`;

export const Description = styled.p`
  color: #616161;
  font-size: 14px;
  line-height: 20px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: left;
`;
