import React, { useState } from "react";
import { Container, Faqs } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import FaqItem from "./FaqItem";

const Faq = ({ content, block }) => {
  const [faqs] = useState(content || []);

  return (
    <Container>
      <PageTitle>{block.title}</PageTitle>
      <Faqs>
        {faqs.map((faq) => (
          <FaqItem faq={faq} key={`faq-${faq.id}`} />
        ))}
      </Faqs>
    </Container>
  );
};

export default Faq;
