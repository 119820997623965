import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  & > a {
    display: inline-block;
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover > a,
  &:hover i {
    color: var(--primary-color);
  }
`;

export const Link = styled.a`
  padding: 20px;
`;

export const Icon = styled.i`
  padding: 5px 0 0 5px;
  color: #333;
  font-size: 13px;
  line-height: 1px;
  text-align: center;
`;

export const Dropdown = styled.div`
  min-width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;

  ${Container}:hover & {
    display: flex;
  }
`;
