import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 70px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

export const Faqs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
