import React, { memo } from "react";
import SambaVideo from "./SambaVideo";
import VideoFile from "./VideoFile";
import File from "./File";
import ImageFile from "./ImageFile";
import { getCurrentUser } from "../../../utils";
// import HandleViewContent from "./handleViewContent";

const ContentMedia = ({ content, setRefreshCourse, updateStats = () => { }, ...props }) => {
    const Medias = () => {
        switch (content.type) {
            case "SAMBA_MEDIA":
                return (
                    <SambaVideo
                        setRefreshCourse={setRefreshCourse}
                        userIri={`/users/${getCurrentUser().id}`}
                        contentIri={`/lms/lesson_contents/${content.id}`}
                        reference={content.samba_media_reference || ""}
                    />
                );
            case "FILE":
                if (
                    content.file.contenttype === "image/jpeg" ||
                    content.file.contenttype === "image/png" ||
                    content.file.contenttype === "image/jpg"
                ) {
                    return (
                        <ImageFile
                            updateStats={updateStats}
                            src={content.file.urlPublica}
                            alt={content.title}
                        />
                    );
                } else if (
                    content.file.contenttype !== "video/mp4" &&
                    content.file.contenttype !== "video/avi") {
                    return (
                        <File
                            setRefreshCourse={setRefreshCourse}
                            userIri={`/users/${getCurrentUser().id}`}
                            content={content}
                            contentIri={`/lms/lesson_contents/${content.id}`}
                        />
                    );
                } else if (
                    content.file.contenttype === "video/mp4" ||
                    content.file.contenttype === "video/avi"
                ) {
                    return (
                        <VideoFile
                            updateStats={updateStats}
                            id={content.id}
                            url={content.file.urlPublica}
                        />
                    );
                }
                break;
            default:
                return content.file ? content.file.urlPublica : "";
        }
    };

    return (
        <div className='medias' style={{ height: "100%" }}>
            <Medias />
        </div>
    );
};

export default memo(ContentMedia);
