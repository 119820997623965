import api from "../../../api";

export const getTrails = (params = {}) => {
  return api.get("/lms/trails", {
    params: {
      ...params,
    },
  });
};

export const getTrailsByKeyword = (keyword) => {
  return api.get(`/lms/trails?trails_search=${keyword}&active=true`);
};
