import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  & a {
    color: #fff;
    font-size: 14px;
  }
`;

export const Icon = styled.i``;
