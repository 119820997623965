import React from "react";
import { stripTags } from "../../../utils/manipulateString";
import {
    Container,
    Image,
    Duration,
    Content,
    Tags,
    Tag,
    Title,
} from "./styles";
import Placeholder from "../../../assets/images/placeholder.jpg";

const CardCourses = ({ course, isCurrentCourse }) => {
    if (!course) return null;
    return (
        <Container>
            <Image url={(course.image && course.image.urlPublica) || Placeholder}>
                <Duration>{course.duration || "00:00"}</Duration>
            </Image>
            <Content className={isCurrentCourse ? 'current' : ''}>
                <Tags>
                    {course.categories &&
                        course.categories.map((category) => (
                            <Tag key={`tag-card-courses-${category.id}`}>
                                {category.title}
                            </Tag>
                        ))}
                </Tags>
                <Title className={isCurrentCourse ? 'current' : ''} dangerouslySetInnerHTML={{ __html: stripTags(course.title) }} />
            </Content>
        </Container>
    );
};

export default CardCourses;
