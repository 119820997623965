import React from "react";
import { Container, Link, Icon, Dropdown } from "./styles";
import DropdownLink from "./DropdownLink";

const MenuDropdownLink = ({ dropdown }) => {
  if (!dropdown) return null;
  return (
    <Container>
      <Link>
        {dropdown.title}
        <Icon className='fas fa-angle-down' />
      </Link>
      <Dropdown>
        {dropdown.menu_items.map((dropdownItem) => (
          <DropdownLink
            link={dropdownItem}
            key={`dropdownItem-${dropdownItem.id}`}
          />
        ))}
      </Dropdown>
    </Container>
  );
};

export default MenuDropdownLink;
