import React from "react";
import { Container, Icon, Title } from "./styles";

const SupportMaterial = ({ supportMaterial }) => {

	const handleClick = (event) => {
		event.preventDefault();

		if (
			supportMaterial.support_material_contents[0] &&
			supportMaterial.support_material_contents[0].file &&
			supportMaterial.support_material_contents[0].file.urlPublica
		) {
			const url = supportMaterial.support_material_contents[0].file.urlPublica;
			window.open(url, "_blank");
		}
	};

	const getFileFormat = (support_material_content) => {

		const format_file = support_material_content.file.content_type;

		if (format_file.includes('pdf')) {
			return <Icon className="far fa-file-pdf" />
		} else if (format_file.includes('sheet')) {
			return <Icon className="far fa-file-excel" colorIcon={{ main: "#1c9c24" }} />
		} else if (format_file.includes('msword')) {
			return <Icon className="far fa-file-word" colorIcon={{ main: "#2051ba" }} />
		} else if (format_file.includes('jpg') || format_file.includes('png') ||
			format_file.includes('jpeg')) {
			return <Icon className="far fa-file-image" colorIcon={{ main: "#fa8212" }} />
		} else if (format_file.includes('mp4') || format_file.includes('avi')) {
			return <Icon className="far fa-file-video" colorIcon={{ main: "#fa8212" }} />
		}
	}

	if (!supportMaterial) return null;
	return (
		<Container onClick={(e) => handleClick(e)}>
			{getFileFormat(supportMaterial.support_material_contents[0])}
			<Title
				dangerouslySetInnerHTML={{ __html: supportMaterial.title || "" }}
			/>
		</Container>
	);
};

export default SupportMaterial;
