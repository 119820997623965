import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Container, Content } from "./styles";
import { PageTitle, Button } from "../../styles/GlobalStyles";
import { CardCourses, LinkHandler } from "../Components";

const CoursesRecommended = ({ content }) => {
  const [courses] = useState(content || []);

  if (!content || !content.length) return null;
  return (
    <Container>
      <Content>
        <PageTitle style={{ marginBottom: "40px" }}>Recomendados</PageTitle>
        <Row style={{ marginBottom: "46px" }}>
          {courses.map((course) => (
            <Col sm='12' lg='4' key={`courseRecommended-card-${course.slug}`}>
              <LinkHandler url={`/curso-interno/${course.slug}`}>
                <CardCourses course={course} />
              </LinkHandler>
            </Col>
          ))}
        </Row>
        <LinkHandler url='/cursos'>
          <Button>Ver todos</Button>
        </LinkHandler>
      </Content>
    </Container>
  );
};

export default CoursesRecommended;
