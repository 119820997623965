import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useExamContext } from "../../../contexts/ExamProvider";
import { getExamApproval } from "../../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../../utils";
import { Container, Strong, Title, Button } from "./styles";
import Modal from "../../Modal";

const CongratsMessage = ({ exam, parentId }) => {
	const history = useHistory();
	const { setExamId, setParentId, setTypeExam } = useExamContext();
	const [resultExam, setResultExam] = useState();
	const [visibleAlert, setVisibleAlert] = useState(false);
	const onDismiss = () => setVisibleAlert(false);

	const handleClick = (event) => {
		event.preventDefault();

		setExamId(exam.id);
		setParentId(parentId);
		setTypeExam("course");
		history.push(`${history.location.pathname}/prova`);
	};

	useEffect(() => {
		const loadResultExam = async () => {
			try {
				const { data } = await getExamApproval(getCurrentUser().id, parentId);
				setResultExam(data && data.length > 0 ? data[0] : null);
			} catch (e) {
				console.error("Exam.onSubmit", e);
				setVisibleAlert(true);
			}
		};

		loadResultExam();
	}, [parentId]);

	if (!exam || !parentId) return null;
	return (
		<Container>

			<Modal open={visibleAlert} toggle={onDismiss} typeMessage="danger">
				Ocorreu um erro ao enviar as respostas. Tente novamente mais tarde.
			</Modal>

			{resultExam && resultExam.approved ?
				(
					<>
						<Title>
							<Strong>PARABÉNS!</Strong>
							<br /> Você concluiu 100% de todas as aulas neste curso
							e foi aprovado com {resultExam.grade_obtainedStr} na prova
						</Title>
					</>
				)
				:
				(
					<>
						<Title>
							<Strong>PARABÉNS!</Strong>
							<br /> Você concluiu 100% de todas as aulas neste curso. Para finalizar
        			realize a avaliação.
     			  </Title>
						<Button onClick={(e) => handleClick(e)}>Fazer Avaliação</Button>
					</>
				)
			}

		</Container>
	);
};

export default CongratsMessage;
