import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 0 0;
  padding: 20px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  cursor: pointer;

  transition: transform 400ms ease, border 400ms ease;

  &:hover {
    border: 1px solid #ddd;
    box-shadow: none;
    transform: scale(1) translate(0px, 6px);
  }
`;

export const Icon = styled.i`
  color: ${props => props.colorIcon ? props.colorIcon.main : '#fa1212'};
  font-size: 30px;
`;

export const Title = styled.p`
  padding-left: 20px;
  color: #000;
  font-size: 12px;
  text-align: left;
`;