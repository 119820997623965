import React, { useState, useEffect } from "react";
import { getEndpoints } from "../services/endpoints";
import { snakeToPascalCase } from "../utils/manipulateString";
import { ComponentFactory } from "./Components";
import { Main, Header, Footer } from "../styles/GlobalStyles";
import About from "./About";

const ComponentBase = ({ children, page }) => {
	const [containers, setContainers] = useState([]);

	const getAllEndpoints = (blocks) => {
		const endPoints = [];
		blocks.forEach((block) => {
			endPoints.push(getEndpoints(block.block.endpoint));
		});

		return endPoints;
	};

	const saveContainers = (blocks, resultEndPoints) => {
		blocks.forEach((block, index) => {
			const blockContent = resultEndPoints[index]
				? resultEndPoints[index].data
				: {};
			const { container } = block;
			const componentName = snakeToPascalCase(block.block.name);
			const content = {
				container: container,
				componentName: componentName,
				content: blockContent,
				block: block.block
			};

			setContainers((containers) => [...containers, content]);
		});
	};

	useEffect(() => {
		const loadComponents = async () => {
			let resultEndPoints = [];
			try {
				const endPoints = getAllEndpoints(page.pages_blocks);
				resultEndPoints = (await Promise.all(endPoints)) || [];
				saveContainers(page.pages_blocks, resultEndPoints);
			} catch (err) {
				saveContainers(page.pages_blocks, resultEndPoints);
				console.error(err);
			}
		};

		loadComponents();
	}, [page]);

	return (
		<>
			<Header>
				{containers
					.filter(({ container }) => container.toLowerCase() === "header")
					.map(({ componentName, content, block }, index) => (
						<React.Fragment key={`header-container-${index}`}>
							{componentName && content && (
								<ComponentFactory
									key={`header-${index}`}
									componentName={componentName}
									content={content}
									block={block}
								/>
							)}
						</React.Fragment>
					))}
			</Header>

			<Main>
				{page && page.content &&
					<About page={page} />
				}

				{containers
					.filter(({ container }) => container.toLowerCase() === "main")
					.map(({ componentName, content, block }, index) => (
						<React.Fragment key={`main-container-${index}`}>
							{componentName && content && (
								<ComponentFactory
									key={`main-${index}`}
									componentName={componentName}
									content={content}
									block={block}
								/>
							)}
						</React.Fragment>
					))}
			</Main>

			{children}

			<Footer>
				{containers
					.filter(({ container }) => container.toLowerCase() === "footer")
					.map(({ componentName, content, block }, index) => (
						<React.Fragment key={`footer-container-${index}`}>
							{componentName && content && (
								<ComponentFactory
									key={`footer-${index}`}
									componentName={componentName}
									content={content}
									block={block}
								/>
							)}
						</React.Fragment>
					))}
			</Footer>
		</>
	);
};

export default ComponentBase;
