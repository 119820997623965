import React, { useState } from "react";
import PropTypes from "prop-types";
import Script from "react-load-script";
import api from "../../../services/api";
import Modal from "../../Modal";

const SambaMedia = ({ reference, contentIri, userIri, setRefreshCourse }) => {
    const idPlayer = "player";
    let flag = false;
    const [visibleAlert, setVisibleAlert] = useState(false);

    const onDismiss = () => setVisibleAlert(false);

    const getSettingMediaKey = async () => {
        try {
            const response = await api.get("/settings/sambatech_player_key");
            return response.data.value;
        } catch (e) {
            setVisibleAlert(true);
            console.error("SambaMedia.getSettingMediaKey", e);
            return "";
        }
    };

    const dispatchActionView = (currentProgress, duration) => {
        const percent = (currentProgress * 100) / (duration / 1000);
        (async () =>
            api.post(`/general/watcheds`, {
                user_iri: userIri,
                progress: Math.floor(percent),
                content_iri: contentIri,
                watched: percent >= 100,
            }))();

        if (setRefreshCourse && percent >= 100) {
            setRefreshCourse(false);
            setRefreshCourse(true);
        }


    };

    const clearPlayer = () => {
        window.document.getElementById(idPlayer).innerHTML = "";
    };

    const eventListener = (player) => {
        let eventParam = parseInt(player.eventParam, 10);

        if (!contentIri || !userIri) { return null }

        switch (player.event) {
            case "onListen":
                if (!flag && eventParam > 1 && eventParam % 15 === 0) {
                    dispatchActionView(eventParam, player.duration);
                    flag = true;
                } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
                    flag = false;
                }
                break;
            case "onFinish":
                eventParam = 100;
                dispatchActionView(eventParam, player.duration);
                break;
            default:
                break;
        }
    };

    const init = async () => {
        const playerKey = await getSettingMediaKey();
        clearPlayer();
        new window.SambaPlayer(idPlayer, {
            ph: playerKey,
            m: reference,
            playerParams: {
                enableShare: false,
            },
            events: {
                "*": eventListener,
            },
        });
    };

    return (
        <>
            <Modal open={visibleAlert} toggle={onDismiss} typeMessage="danger">
                Ocorreu um erro ao enviar as respostas. Tente novamente mais tarde.
			</Modal>

            <Script
                url='https://player.sambatech.com.br/v3/samba.player.api.js'
                onLoad={init}
            />
            <div
                className='embed-responsive embed-responsive-16by9'
                style={{ height: "100%" }}
            >
                <div
                    className='embed-responsive-item'
                    id={idPlayer}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ></div>
            </div>
        </>
    );
};

SambaMedia.propTypes = {
    reference: PropTypes.string.isRequired,
    contentIri: PropTypes.string.isRequired,
};

export default SambaMedia;
