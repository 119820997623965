import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0 20px;
  padding: 0 30px 14px;
  border: 1px solid #eee;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  max-height: ${({ isVisible }) => (isVisible ? "3000px" : "108px")};
  transition: max-height 0.5s ease-in-out;
`;

export const Header = styled.div`
  width: 100%;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled.h5`
  margin: 0 0 15px;
  color: #333;
  font-size: 17px;
  font-weight: 500;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;

export const Progress = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Quantity = styled.p`
  margin-right: 20px;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;

export const ProgressBar = styled.div`
  width: 85%;
  height: 6px;
  position: relative;
  border-radius: 100px;
  background-color: #dbdbdb;

  &:before {
    content: "";
    width: ${({ progress }) => `${progress || 0}%`};
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 100px;
    background-color: #00985f;
    background-image: linear-gradient(45deg, var(--primary-color), #08c5ff);
  }
`;

export const Icon = styled.i`
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  line-height: 30px;
`;

export const Courses = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
