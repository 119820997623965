import React, { useState, useEffect, useCallback } from "react";
import { Spinner } from "reactstrap";
import {
    getWatched,
    updateWatcheds,
} from "../../../../services/endpoints/general/watcheds";
import { getCurrentUser } from "../../../../utils";
import { Container } from "./styles";

const WatchedButton = ({ lesson, setRefreshCourse }) => {
    const [isWatched, setIsWatched] = useState(false);
    const [loading, setLoading] = useState(false);

    const isLessonWatched = useCallback(async () => {
        const lessonContentId =
            lesson && lesson.lesson_contents && lesson.lesson_contents[0]
                ? lesson.lesson_contents[0].id
                : null;

        try {
            const { data } = await getWatched(
                `/lms/lesson_contents/${lessonContentId}`
            );

            return data && data[0] && data[0].watched;
        } catch (error) {
            console.error("CheckedInfo.isLessonChecked:", error);
        }
    }, [lesson]);

    useEffect(() => {
        const verifyLessonWatched = async () => {
            setIsWatched(await isLessonWatched());
        };

        verifyLessonWatched();
    }, [isLessonWatched]);

    const updateLessonStats = async (event) => {
        event.preventDefault();
        setLoading(true);
        setRefreshCourse(false);

        const lessonContentId =
            lesson && lesson.lesson_contents && lesson.lesson_contents[0]
                ? lesson.lesson_contents[0].id
                : null;

        const lessonCompleted = {
            user_iri: `users/${getCurrentUser().id}`,
            progress: 100,
            content_iri: `/lms/lesson_contents/${lessonContentId}`,
            watched: true,
        };

        const lessonNotCompleted = {
            user_iri: `users/${getCurrentUser().id}`,
            progress: 0,
            content_iri: `/lms/lesson_contents/${lessonContentId}`,
            watched: false,
        };

        await updateWatcheds(lessonNotCompleted);

        try {
            const body = (await isLessonWatched())
                ? lessonNotCompleted
                : lessonCompleted;

            await updateWatcheds(body);
            setIsWatched((prev) => !prev);
            setRefreshCourse(true);
            setLoading(false);
        } catch (err) {
            console.error("CheckedInfo.updateLessonStats", err);
        }
    };

    return (
        <Container isWatched={isWatched} onClick={(e) => updateLessonStats(e)}>
            {loading ? <Spinner className="m-1" color="primary" /> : "VISTO"}
        </Container>
    );
};

export default WatchedButton;
