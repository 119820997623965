import React, { useState } from "react";
import { Container, Title, Description } from "./styles";
import ActualContent from "./ActualContent";
import WatchedButton from "./WatchedButton";
import { stripTags } from "../../../utils/manipulateString";

const Lesson = ({ lesson, setRefreshCourse }) => {
    const [actualContent] = useState(0);

    if (!lesson) return null;
    return (
        <Container>
            {lesson.lesson_contents && lesson.lesson_contents[actualContent] && (
                <ActualContent setRefreshCourse={setRefreshCourse} content={lesson.lesson_contents[actualContent]} />
            )}
            <WatchedButton setRefreshCourse={setRefreshCourse} lesson={lesson} />
            <Title dangerouslySetInnerHTML={{ __html: stripTags(lesson.title) }} />
            <Description
                dangerouslySetInnerHTML={{ __html: stripTags(lesson.description) }}
            />
        </Container>
    );
};

export default Lesson;
