import React, { useEffect } from "react";
import Amplify from "aws-amplify";
import {
  refreshToken,
  checkTokenExpires,
  hasAuthSignToken,
  getAuthSignTokens,
  setTokensAtLocalStorage,
  removeRelatedCookies,
} from "./RefreshTokenHandlers";

Amplify.configure({
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDP_ID,
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {},
});

const RefreshToken = () => {
  const remainingSeconds = 300; // Remaining seconds to token expire

  useEffect(() => {
    if (hasAuthSignToken()) {
      const tokens = getAuthSignTokens();
      setTokensAtLocalStorage(tokens);
      removeRelatedCookies();
    }

    refreshToken();

    const interval = checkTokenExpires(remainingSeconds);
    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default RefreshToken;
