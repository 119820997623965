import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCategoriesCourse, getCourses } from '../../services/endpoints/lms/courses';
import queryString from "query-string";
import { Row, Col, Spinner } from "reactstrap";
import { Container, Content } from "../../components/Courses/styles";
import { CardCourses, ComponentBase, LinkHandler } from "../../components/Components";
import { Button } from '../../styles/GlobalStyles';
import { PageTitle } from "../../styles/GlobalStyles";
import { getPagesBySlug } from '../../services/endpoints/cms/pages';
import CategoriesFilter from '../../components/CategoriesFilter';


function Courses() {
	const perPage = 9;
	const [courses, setCourses] = useState([]);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const { category_id } = queryString.parse(history.location.search);
	const [currentPage, setCurrentPage] = useState(perPage);
	const [page, setPage] = useState({});

	useEffect(() => {
		const loadCategories = async () => {
			try {
				const { data } = await getCategoriesCourse();
				setCategories(data || []);
			} catch (err) {
				console.error(err);
			}
		};
		loadCategories();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const response = await getPagesBySlug('cursos');
				setPage(response.data[0] || null);
			} catch (err) {
				console.error(err);
			}
		})()
	}, []);

	useEffect(() => {
		const loadCourses = async () => {
			try {
				const { data } = await getCourses({ category_id });
				setCourses(data);
				setLoading(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		};
		loadCourses();
	}, [category_id]);

	const handlePagination = () => {
		setCurrentPage(prevValue => prevValue + perPage);
	}

	if (loading) { return (<div className="text-center"><Spinner className="m-5" color="primary" /></div>) }
	return (

		<ComponentBase page={page}>
			<Container className="mt-5">
				<Content>
					<Row className="justify-content-between">
						<PageTitle>Cursos</PageTitle>
						<CategoriesFilter categoriesFilter={categories} />
					</Row>
					{courses.length ? (
						<Row style={{ marginBottom: "46px" }}>
							{courses.slice(0, currentPage).map((course) => (
								<Col sm='12' lg='4' key={`courses-card-${course.slug}`}>
									<LinkHandler url={`/curso-interno/${course.slug}`}>
										<CardCourses course={course} />
									</LinkHandler>
								</Col>
							))}
						</Row>
					) : (
							<p>Não possui nenhum registro</p>
						)}
				</Content>
				{courses.length > 0 && courses.length > currentPage &&
					<div className="text-center">
						<Button onClick={handlePagination}>Carregar mais</Button>
					</div>
				}
			</Container>
		</ComponentBase>

	);
}

export default Courses;