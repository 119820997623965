import React from "react";
import { stripTags } from "../../../utils/manipulateString";
import { Container, Thumbnail, Title, Remove } from "./styles";
import { Button } from "../../../styles/GlobalStyles";
import { LinkHandler } from "../../Components";
import Placeholder from "../../../assets/images/placeholder.jpg";
import { getCurrentUser } from "../../../utils";
import { updateFavorites } from "../../../services/endpoints/general/evaluations";

const CardFavorites = ({ favorite, setReload }) => {
  const removeFavorite = async () => {
    try {
      const body = {
        user_iri: `/users/${getCurrentUser().id}`,
        content_iri: `/lms/courses/${favorite.id}`,
        value: "1",
        type: "FAVORITE",
      };
      await updateFavorites(body);
      setReload(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    const confirm = window.confirm(
      "Tem certeza que deseja remover o curso da lista de favoritos?"
    );
    confirm && removeFavorite();
  };

  if (!favorite) return null;
  return (
    <Container>
      <LinkHandler url={`curso-interno/${favorite.slug}`}>
        <Thumbnail
          url={(favorite.image && favorite.image.urlPublica) || Placeholder}
        />
      </LinkHandler>

      <LinkHandler url={`curso-interno/${favorite.slug}`}>
        <Title
          dangerouslySetInnerHTML={{ __html: stripTags(favorite.title) }}
        />
      </LinkHandler>
      <Remove onClick={(e) => handleClick(e)}>Remover</Remove>
      <LinkHandler url={`curso-interno/${favorite.slug}`}>
        <Button>Acessar</Button>
      </LinkHandler>
    </Container>
  );
};

export default CardFavorites;
