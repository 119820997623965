import React, { useState } from "react";
import { Container, Icon } from "./styles";
import Menu from "./Menu";

const MobileMenu = ({ menu, profileMenu }) => {
	const [isVisible, setIsVisible] = useState(false);

	if (!menu) return null;
	return (
		<Container>
			<Icon
				className='fas fa-bars'
				isVisible={isVisible}
				onClick={(e) => setIsVisible((prev) => !prev)}
			/>
			<Menu menu={menu} isVisible={isVisible} profileMenu={profileMenu} />
		</Container>
	);
};

export default MobileMenu;
