import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled.div`
  width: 100%;
`;

export const Banner = styled.div`
  width: 100%;
	min-width: 350px; 
  height: calc(100vh - 60px);
  background-image: url(${({ url }) => url});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

	@media screen and (max-width: 575px) {
		height: 100vh;
		background-image: url(${({ urlMobile }) => urlMobile});
  }
`;

export const Info = styled(GlobalContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 1140px) {
    align-items: left;
  }

`;

export const Title = styled.h1`
  z-index: 2;
  color: #fff;
  font-size: 48px;
  font-weight: bold;
	line-height: 70px;
	white-space: normal;
	text-align: left;
	width: 600px;

	@media (min-width: 768px) { 
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 0px;
	}


	@media screen and (max-width: 575px) {
		width: 100%;
		line-height: 50px;
		font-size: 40px;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 0px;
  }
`;

export const Description = styled.p`
  z-index: 2;
  margin-top: 20px;
	margin-bottom: 30px;
	width: 600px;
  color: #fff;

	@media (min-width: 768px) { 
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 0px;
	}

	@media screen and (max-width: 575px) {
		width: 100%;
		font-size: 12px;
		padding-left: 10px;
		padding-right: 10px;
  }

`;
