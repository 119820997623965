import api from "../../../api";

export const getMenus = () => {
  return api.get("/cms/menus");
};

export const getMenusById = (id, params = {}) =>
  api.get("/cms/menus", {
    params: {
      id,
      ...params,
    },
  });
