import React, { useState, useEffect, useCallback } from "react";
import {
  getCoursesOngoing,
  getCoursesNotStarded,
  getCoursesFinished,
  getCoursesTotal,
} from "../../services/endpoints/lms/courses";
import { Container, Content, Title } from "./styles";
import Status from "./Status";

const UserCoursesStats = () => {
  const [coursesTotal, setCoursesTotal] = useState(0);
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [notStartedCourses, setNotStartedCourses] = useState([]);
  const [finishedCourses, setFinishedCourses] = useState([]);

  const loadOngoingCourses = useCallback(async () => {
    try {
      const { data } = await getCoursesOngoing();
      setOngoingCourses(
        (data && data.map((ongoingCourse) => ongoingCourse.course)) || []
      );
    } catch (err) {
      console.error(err);
    }
  }, [setOngoingCourses]);

  const loadNotStartedCourses = useCallback(async () => {
    try {
      const { data } = await getCoursesNotStarded();
      setNotStartedCourses(data || []);
    } catch (err) {
      console.error(err);
    }
  }, [setNotStartedCourses]);

  const loadFinishedCourses = useCallback(async () => {
    try {
      const { data } = await getCoursesFinished();
      setFinishedCourses(
        (data && data.map((finishedCourse) => finishedCourse.course)) || []
      );
    } catch (err) {
      console.error(err);
    }
  }, [setFinishedCourses]);

  const loadCoursesTotal = useCallback(async () => {
    try {
      const { data } = await getCoursesTotal();
      setCoursesTotal(data || []);
    } catch (err) {
      console.error(err);
    }
  }, [setCoursesTotal]);

  useEffect(() => {
    loadOngoingCourses();
    loadNotStartedCourses();
    loadFinishedCourses();
    loadCoursesTotal();
  }, [
    loadOngoingCourses,
    loadNotStartedCourses,
    loadFinishedCourses,
    loadCoursesTotal,
  ]);

  return (
    <Container>
      <Title>Andamento dos Meus Cursos</Title>
      <Content>
        <Status
          title='Em andamento'
          courses={ongoingCourses}
          totalCourses={coursesTotal}
        />
        <Status
          title='Não iniciado'
          courses={notStartedCourses}
          totalCourses={coursesTotal}
        />
        <Status
          title='Concluídos'
          courses={finishedCourses}
          totalCourses={coursesTotal}
        />
      </Content>
    </Container>
  );
};

export default UserCoursesStats;
