import React from "react";
import { Container, Logo, Divisor, Info, Text } from "./styles";
import LogoImage from "../../assets/images/logo-white.png";

const Footer = () => {
  return (
    <Container>
      <Logo url={LogoImage} />
      <Divisor />
      <Info>
        <Text>© 2020 ZARAPLAST</Text>
        <Text>Suporte</Text>
      </Info>
    </Container>
  );
};

export default Footer;
