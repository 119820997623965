import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled.div`
  margin-top: 55px;
  padding-top: 25px;
  padding-bottom: 35px;
  width: 100%;
  background-color: hsla(0, 0%, 97.3%, 0.78);
`;

export const Content = styled(GlobalContainer)``;
