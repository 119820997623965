import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: url(${({ url }) => url});
  background-position: 50% 30%;
  background-size: cover;
`;

export const Content = styled(GlobalContainer)``;

export const Title = styled.h1`
  margin: 0 0 20px;
  color: #f8f8f8;
  font-size: 30px;
`;

export const Description = styled.p`
  color: #f8f8f8;
  font-size: 12px;
`;
