import styled from "styled-components";

export const Container = styled.div`
  margin: auto 0 0;
  padding: 25px 15px;
  width: 100%;
  display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00985f;
  color: #fff;
	border-radius: 12px;
`;

export const Title = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const Strong = styled.strong`
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
`;

export const Button = styled.button`
	text-align: center;
  margin: 20px 10px 0 30px;
  padding: 10px 20px 0px 20px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;
	pointer-events: ${({ approved }) => (approved ? "none" : "")};

  &:hover {
    border-color: transparent;
    background-color: var(--secondary-color);
  }

  @media screen and (max-width: 679px) {
    padding: 10px 25px;
  }
`;
