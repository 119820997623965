import React from "react";
import { stripTags, getFirstLetters } from "../../../../utils/manipulateString";
import {
	Container,
	Note,
	NoteDetails,
	Name,
	CreatedAt,
	Description,
} from "./styles.js";
import { Thumbnail } from "../styles";

const NoteList = ({ notes }) => {
	if (!notes) return null;
	return (
		<Container>
			{notes.map((note) => (
				<Note key={`note-${note.id}`}>
					<Thumbnail>{getFirstLetters(note.user.name)}</Thumbnail>
					<NoteDetails>
						<Name>{note.user.name}</Name>
						<CreatedAt>{note.created_at}</CreatedAt>
						<Description
							dangerouslySetInnerHTML={{
								__html: stripTags(note.description),
							}}
						/>
					</NoteDetails>
				</Note>
			))}
		</Container>
	);
};

export default NoteList;
