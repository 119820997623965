import React, { useState } from "react";
import { stripTags } from "../../../utils/manipulateString";
import {
    Container,
    ThumbnailVideo,
    Image,
    Duration,
    Content,
    Title,
} from "./styles";
import Placeholder from "../../../assets/images/placeholder.jpg";

const CardLessons = ({ lesson, isCurrentLesson }) => {

    const [lessonContent] = useState(
        (lesson && lesson.lesson_contents && lesson.lesson_contents[0]) || {}
    );

    if (!lesson) return null;
    return (
        <Container>
            {lessonContent.file &&
                (lessonContent.file.content_type !== "video/mp4" ||
                    lessonContent.file.content_type !== "video/avi") ? (
                    <Image
                        url={(lessonContent.thumbnail && lessonContent.thumbnail.urlPublica) || Placeholder}
                    ></Image>
                ) : (
                    <ThumbnailVideo
                        url={
                            (lessonContent &&
                                lessonContent.thumbnail &&
                                lessonContent.thumbnail.urlPublica) ||
                            Placeholder
                        }
                    >
                        {lessonContent.samba_media_duration && (
                            <Duration>{lessonContent.samba_media_duration}</Duration>
                        )}
                    </ThumbnailVideo>
                )}
            <Content className={isCurrentLesson ? 'current' : ''}>
                <Title dangerouslySetInnerHTML={{ __html: stripTags(lesson.title) }} />
            </Content>
        </Container>
    );
};

export default CardLessons;
