import React, { useState } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { Container, Content } from "./styles";
import { PageTitle, Button } from "../../styles/GlobalStyles";
import { CardCertificates, ComponentBase } from "../../components/Components";
import { getExamApproval } from "../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../utils";
import { useEffect } from "react";
import { getPagesBySlug } from "../../services/endpoints/cms/pages";
import { getCertificates } from "../../services/endpoints/lms/certificates";
import CategoriesFilter from "../../components/CategoriesFilter";

const PageCertificates = () => {
	const perPage = 9;
	const categories = [{ id: 1, title: 'Cursos' }, { id: 2, title: 'Trilhas' }];
	const [certificates, setCertificates] = useState();
	const [isApproved, setIsApproved] = useState(false);
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(perPage);
	const [page, setPage] = useState({});
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				const response = await getPagesBySlug('certificados');
				setPage(response.data[0] || null);
			} catch (err) {
				console.error(err);
			}
		})()
	}, []);

	const hasMandatoryExam = (course) => {
		if (
			course &&
			course.course_exams &&
			course.course_exams[0] &&
			course.course_exams[0].exam &&
			course.course_exams[0].exam.mandatory_approval
		) {
			verifyApproval(course.id);
			return true;
		}
		return false;
	};

	const verifyApproval = async (courseId) => {
		try {
			const { data } = await getExamApproval(getCurrentUser().id, courseId);
			setIsApproved(data[0].approved);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		const loadCertificates = async () => {
			try {
				const { data } = await getCertificates({ page: 0, perPage: currentPage });
				setCertificates(data || null);
				setLoading(false);
				setShowSpinner(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
				setShowSpinner(false);
			}
		};

		loadCertificates();
	}, [currentPage, perPage]);

	const handlePagination = () => {
		setShowSpinner(true);
		setCurrentPage(prevValue => prevValue + perPage);
	}

	if (loading || !page.pages_blocks) { return null }
	return (
		<ComponentBase page={page}>
			<Container>
				<Content>
					<Row className="justify-content-between">
						<PageTitle>{page.title}</PageTitle>
						<CategoriesFilter categoriesFilter={categories} />
					</Row>
					{certificates && certificates.length ?
						(
							<Row style={{ marginBottom: "46px" }}>
								{certificates.map((certificate) =>
									hasMandatoryExam(certificate.course) ? (
										isApproved && (
											<Col sm='12' lg='4' key={`certificate-card-${certificate.id}`}>
												<CardCertificates certificate={certificate} />
											</Col>
										)
									) : (
											<Col sm='12' lg='4' key={`certificate-card-${certificate.id}`}>
												<CardCertificates certificate={certificate} />
											</Col>
										)
								)}
							</Row>
						)
						:
						<Row style={{ marginBottom: "277px" }}>
							<p>Não existe registro de certificados</p>
						</Row>
					}
				</Content>
				{showSpinner && <div className="text-center"><Spinner className="m-5" color="primary" /></div>}
				{certificates && certificates.length > 0 && certificates.length >= currentPage &&
					<div className="text-center">
						<Button onClick={handlePagination}>Carregar mais</Button>
					</div>
				}
			</Container>
		</ComponentBase>
	);
};

export default PageCertificates;
