import api from "../../../api";

export const getCourses = (params = {}) => {
	return api.get("/lms/courses", {
		params: {
			...params,
		},
	});
};

export const getCoursesTotal = () => {
	return api.get("/lms/courses/count_by");
};

export const getCoursesBySlug = (slug, params = {}) =>
	api.get("/lms/courses", {
		params: {
			slug,
			...params,
		},
	});

export const getCoursesByKeyword = (keyword) => {
	return api.get(`/lms/courses?course_search=${keyword}&active=true`);
};

export const getCoursesByCategory = (category) => {
	return api.get(`/lms/courses?category_id=${category}`);
};

export const getCoursesOngoing = () => {
	return api.get(`/lms/course_stats?course_status=EM_ANDAMENTO`);
};

export const getCoursesFinished = () => {
	return api.get(`/lms/course_stats?course_status=CONCLUIDO`);
};

export const getCoursesNotStarded = () => {
	return api.get(`/lms/courses/not_started`);
};

export const getCoursesMostWatcheds = () => {
	return api.get(`/lms/courses?order[views]=desc`);
};

export const getCategoriesCourse = () => {
	return api.get(`/lms/categories`);
}