import styled from "styled-components";

export const Form = styled.form`
  padding: 20px;
  flex-grow: ${({ isVisible }) => (isVisible ? "1" : "0")};
  width: ${({ isVisible }) => (isVisible ? "auto" : "0")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
`;

export const Input = styled.input`
  padding: ${({ isVisible }) => (isVisible ? "10px" : "0")};
  width: ${({ isVisible }) => (isVisible ? "100%" : "0")};
  border-radius: 18px;
  box-shadow: inset 1px 1px 3px 0 rgba(79, 82, 88, 0.34);
`;

export const Button = styled.button`
  padding: ${({ isVisible }) => (isVisible ? "10px" : "10px 0")};
  background-color: transparent;
  cursor: pointer;
`;

export const Icon = styled.i`
  border: 1px solid transparent;
  background-color: transparent;
  color: #333;
  display: inline-block;
  text-decoration: none;
  border-radius: 0;
  line-height: inherit;
`;
