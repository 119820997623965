import styled from "styled-components";

export const Container = styled.div`
  margin: 60px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dcdada;
`;

export const Form = styled.form`
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Input = styled.textarea`
  flex: 1;
  margin: 0 10px;
  padding: 8px 12px;
  min-height: 38px;
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 13px;
  color: #333333;

  &:focus {
    border-color: #3898ec;
    outline: 0;
  }
`;
