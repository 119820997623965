import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { stripTags } from "../../utils/manipulateString";
import { Container, CardContent, Icon, Title, Description } from "./styles";
import { LinkHandler } from "../Components";

const HomeCards = ({ content }) => {
  const [cards] = useState((content && content.features) || []);

  if (!content) return null;
  return (
    <Container>
      <Row>
        {cards.map((card) => (
          <Col xs='12' lg='4' key={`homeCard-${card.id}`}>
            <LinkHandler url={card.link}>
              <CardContent>
                <Icon className={card.icon} />
                <Title
                  dangerouslySetInnerHTML={{ __html: stripTags(card.title) }}
                />
                <Description
                  dangerouslySetInnerHTML={{
                    __html: stripTags(card.description),
                  }}
                />
              </CardContent>
            </LinkHandler>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default HomeCards;
