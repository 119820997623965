import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 20px;
  width: 80%;
  height: auto;
  position: fixed;
  top: 60px;
	color: #fff;
  right: 0;
	display: block;
  transform: ${({ isVisible }) =>
		isVisible ? "translateX(0%)" : "translateX(200%)"};
  transition: transform 0.5s ease-in-out;
  background-color: #0e0e0e;
	border-radius: 20px;
`;



export const Link = styled.div`
  height: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
`;

export const IconProfile = styled.i`
  padding: 0 5px 0 0;
  color: #fff;
  font-size: 14px;
  text-align: left;
`;

export const IconAngle = styled.i`
  padding: 0 0 0 5px;
  color: #fff;
  font-size: 13px;
  text-align: left;
`;

export const Dropdown = styled.div`
  min-width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;

  ${Container}:hover & {
    display: flex;
  }
`;
