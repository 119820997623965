import React, { useState } from "react";
import { Button } from "../../../../styles/GlobalStyles";
import { postComment } from "../../../../services/endpoints/general/comments";
import { getFirstLetters } from "../../../../utils/manipulateString";
import { Container, Form, Input } from "./styles";
import { Thumbnail } from "../styles";
import Modal from "../../../Modal";

const CreateNote = ({ currentLessonId, user, setReload }) => {

    const [comment, setComment] = useState("");
    const [visible, setVisible] = useState(false);
    const [typeMessage, setTypeMessage] = useState("success");
    const [messageAlert, setMessageAlert] = useState('')

    const onDismiss = () => setVisible(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = {
            user_iri: `/users/${user.id}`,
            parent_iri: `/lms/lessons/${currentLessonId}`,
            description: comment,
        };
        try {
            await postComment(body);
            setComment("");
            setReload((prev) => !prev);
            setVisible(true);
            setTypeMessage("success");
            setMessageAlert("Anotação registrada com sucesso!");
        } catch (error) {
            setTypeMessage("danger");
            setMessageAlert("Houve algum problema ao registrar!");
        }
    };

    return (
        <Container>

            <Modal open={visible} toggle={onDismiss} typeMessage={typeMessage}>
                {messageAlert}
            </Modal>

            <Form onSubmit={(e) => handleSubmit(e)}>
                <Thumbnail>{getFirstLetters(user.name || "")}</Thumbnail>
                <Input
                    type='text'
                    maxlength='256'
                    placeholder='Escreva uma anotação'
                    value={comment}
                    required
                    onChange={(e) => setComment(e.target.value)}
                />
                <Button type='submit'>Salvar</Button>
            </Form>
        </Container>
    );
};

export default CreateNote;
