import React, { useState } from "react";
import { Container, Link, IconProfile, IconAngle, Dropdown } from "./styles";
import { getCurrentUser } from "../../../../utils";
import DropdownLink from "../LeftMenu/MenuDropdownLink/DropdownLink";
import { nameFormat } from "../../../../utils/manipulateString";

const ProfileMenu = ({ menu }) => {
  const [user] = useState(getCurrentUser());

  if (!menu) return null;
  return (
    <Container>
      <Link>
        <IconProfile className='far fa-user-circle' />
        {user && nameFormat(user.name)}
        <IconAngle className='fas fa-angle-down' />
      </Link>
      <Dropdown>
        {menu.map((dropdownItem) => (
          <DropdownLink
            link={dropdownItem}
            key={`dropdownItem-${dropdownItem.id}`}
          />
        ))}
      </Dropdown>
    </Container>
  );
};

export default ProfileMenu;
