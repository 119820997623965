export const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("zaraplast.user"));
};

export const isExternalUrl = (url) => {
	if (url) {
		return (
			url.includes("http:") || url.includes("https:") || url.includes("www.")
		);
	}

	return false;
};

export const updateBlockEndpoint = (blocks, blockName, newEndpoint) => {
	if (blocks && blockName) {
		return blocks.map((block) => {
			if (block.block.name === blockName) {
				block.block.endpoint = newEndpoint;
			}
			return block;
		});
	}
};

export const removeDuplicatesByProperty = (array, property) => {
	if (array && array.length && property) {
		const arrayStorage = {};

		array.forEach((item) => {
			if (!arrayStorage[item[property]]) {
				arrayStorage[item[property]] = item;
			}
		});

		const newArray = [];
		for (let item in arrayStorage) {
			newArray.push(arrayStorage[item]);
		}

		return newArray;
	}

	return array;
};

