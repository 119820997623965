import styled from "styled-components";

export const Container = styled.div`
  padding: 23px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 6px 13px -8px #6d6b6b;
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Divisor = styled.div`
  width: 150px;
  height: 1px;
  margin-top: 0px;
  background-color: #f0f0f0;
  color: #b4a9a9;
`;

export const Value = styled.p`
  color: var(--primary-color);
  font-size: 35px;
  font-weight: 700;
`;

export const Icon = styled.i`
  color: var(--primary-color);
  font-size: 35px;
  cursor: pointer;
`;

export const Span = styled.span`
  margin: 10px 0 0;
  color: #616161;
  font-size: 14px;
  line-height: 20px;
`;
