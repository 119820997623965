import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 70px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const Item = styled.div`
  margin-top: 15px;
  padding: 15px;
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px none #ddd;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #d6d6d6;
`;

export const ProfilePicture = styled.div`
  margin: 0 0 10px;
  padding: 30px;
  width: 15%;
  height: 30%;
  border-radius: 100px;
  background-color: transparent;
  background-image: url(${({ url }) => url});
  background-position: 40% 60%;
  background-size: cover;
`;

export const Title = styled.h5`
  color: #333;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;
export const Span = styled.span`
  color: #616161;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;
