import React from "react";
import { Row, Col } from "reactstrap";
import { Container, Content } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import { CardTrails, LinkHandler } from "../Components";

const Trails = ({ content, block }) => {
	const trails = content;

	if (!content || !content.length) return null;
	return (
		<Container>
			<Content>
				<PageTitle>Trilhas</PageTitle>
				<Row style={{ marginBottom: "46px" }}>
					{trails.map((trail) => (
						<Col sm='12' lg='4' key={`trails-card-${trail.id}`}>
							<LinkHandler url={`/trilha-interna/${trail.id}`}>
								<CardTrails trail={trail} />
							</LinkHandler>
						</Col>
					))}
				</Row>
			</Content>
		</Container>
	);
};

export default Trails;
