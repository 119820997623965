import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Container, Title, Icon, Card, PrevArrow, NextArrow } from "./styles";
import { CardCourses } from "../../Components";
import { getExamLessonApproval } from "../../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../../utils";
import Slider from "react-slick";

const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return <PrevArrow className={className} onClick={onClick} />;
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return <NextArrow className={className} onClick={onClick} />;
};

const OtherCourses = ({ trail, setCurrentCoursePosition, currentCoursePosition }) => {
    const [courses, setCourses] = useState((trail && trail.trails_courses) || []);
    const [dataIcons, setDataIcons] = useState([]);

    useEffect(() => {

        const loadIcons = () => {
            setDataIcons([]);
            setCourses((trail && trail.trails_courses) || []);
            courses.map(async (course, index) => {
                handleIconType(index);
            });
        }

        loadIcons();

    }, [courses, currentCoursePosition, trail]);

    const getCourseStats = async (coursePosition) => {

        const concluido = "CONCLUIDO";
        const liberado = "LIBERADO";
        const emAndamento = "EM_ANDAMENTO";
        const bloqueado = "BLOQUEADO";

        const courseStats = (
            courses &&
                courses[coursePosition] &&
                courses[coursePosition].course &&
                courses[coursePosition].course.course_stats ?
                courses[coursePosition].course.course_stats :
                null
        );

        const existExam = (
            courseStats &&
                courses[coursePosition].course.course_exams &&
                courses[coursePosition].course.course_exams[0] ?
                courses[coursePosition].course.course_exams[0] : null);

        const previousCourse = (courses[coursePosition - 1] && courses[coursePosition].course ? courses[coursePosition - 1].course : null);

        const lastLesson = (
            courses &&
                courses[coursePosition] &&
                courses[coursePosition].course &&
                courses[coursePosition].course.lessons &&
                courses[coursePosition].course.lessons.length ?
                courses[coursePosition].course.lessons[courses[coursePosition].course.lessons.length - 1] :
                null
        );

        if (checkedCourseUnlocked(courseStats, previousCourse, coursePosition)) {
            return liberado;
        } else if (await checkedCourseInProgress(existExam, courseStats, lastLesson)) {
            return emAndamento;
        } else if (checkCourseConcluded(existExam, courseStats)) {
            return concluido;
        } else if (courseStats && courseStats.length > 0 && courseStats[0].progress !== 100 &&
            previousCourse &&
            previousCourse.course_stats.length &&
            previousCourse.course_stats[0].course_status === emAndamento) {
            return bloqueado;
        }

    };

    const checkedCourseUnlocked = (courseStats, previousCourse, coursePosition) => {

        const existExamPreviousCourse = (
            previousCourse &&
                previousCourse.course_exams &&
                previousCourse.course_exams[0] ?
                previousCourse.course_exams[0] : null);

        return (
            (
                courseStats && courseStats.length === 0 && coursePosition === 0
            )

            ||

            (
                courseStats && courseStats.length === 0 &&
                previousCourse &&
                previousCourse.course_stats &&
                previousCourse.course_stats[0] &&
                previousCourse.course_stats[0].course_status === "CONCLUIDO" &&
                existExamPreviousCourse &&
                existExamPreviousCourse.exam &&
                existExamPreviousCourse.exam.mandatory_approval &&
                previousCourse.course_stats[0].max_grade >= previousCourse.course_stats[0].approval_percentage
            )

            ||

            (
                courseStats && courseStats.length === 0 &&
                previousCourse &&
                previousCourse.course_stats &&
                previousCourse.course_stats.length &&
                previousCourse.course_stats[0] &&
                previousCourse.course_stats[0].course_status === "CONCLUIDO" &&
                existExamPreviousCourse &&
                existExamPreviousCourse.exam &&
                !existExamPreviousCourse.exam.mandatory_approval &&
                previousCourse.course_stats[0].attempts > 0
            )

            || (
                courseStats && courseStats.length === 0 &&
                previousCourse &&
                previousCourse.course_stats &&
                previousCourse.course_stats.length &&
                previousCourse.course_stats[0] &&
                previousCourse.course_stats[0].course_status === "CONCLUIDO" &&
                !existExamPreviousCourse
            )

        );

    }

    const checkedCourseInProgress = async (existExam, courseStats, lastLesson) => {

        const existExamsLesson = (
            lastLesson &&
                lastLesson.lesson_exams &&
                lastLesson.lesson_exams.length ?
                lastLesson.lesson_exams :
                null
        );

        let resultExamsLesson = null;
        if (existExamsLesson) {
            const resultsExams = await Promise.all(existExamsLesson.map(async (lessonExam) => await checkExamLesson(lessonExam, lastLesson)));
            resultExamsLesson = resultsExams.every((res) => !!res);
        } else {
            resultExamsLesson = true;
        }

        return (
            (
                courseStats && courseStats.length > 0 && courseStats[0].progress < 100
            )
            ||
            (
                courseStats && courseStats.length > 0 &&
                courseStats[0].progress === 100 && !resultExamsLesson
            )
            ||
            (
                existExam && existExam.exam &&
                existExam.exam.mandatory_approval &&
                courseStats.length > 0 &&
                courseStats[0].max_grade < courseStats[0].approval_percentage
            )
            ||
            (
                existExam && existExam.exam &&
                !existExam.exam.mandatory_approval &&
                courseStats.length > 0 &&
                courseStats[0].attempts <= 0
            )
        );
    }


    const checkCourseConcluded = (existExam, courseStats) => {

        if (existExam && existExam.exam &&
            existExam.exam.mandatory_approval) {

            return (
                courseStats.length > 0 &&
                courseStats[0].max_grade >= courseStats[0].approval_percentage
            );

        }

        return (
            (
                existExam && !existExam.exam.mandatory_approval &&
                courseStats.length > 0 && courseStats[0].progress === 100 &&
                courseStats[0].attempts > 0
            ) ||

            (
                !existExam && courseStats && courseStats.length > 0 &&
                courseStats[0].progress === 100
            )
        );

    }

    const checkExamLesson = async (lessonExam, lesson) => {

        const resultExam = await getResultExam(lesson.id, lessonExam.exam);
        if (!resultExam) {
            return false;
        }

        if (resultExam && resultExam.length && lessonExam.exam.mandatory_approval && resultExam[0].approved) {
            return true;
        }

        if (!lessonExam.exam.mandatory_approval && resultExam && resultExam.length) {
            return true;
        }

    }

    const getResultExam = async (lessonId, exam) => {
        try {
            const { data } = await getExamLessonApproval(getCurrentUser().id, lessonId, exam.id);
            return (data && data.length ? data : null);
        } catch (e) {
            console.error("Exam.onSubmit", e);
        }
    }

    const handleIconType = async (coursePosition) => {

        const courseStats = await getCourseStats(coursePosition);
        let dataIconsCopy = dataIcons;

        switch (true) {
            case courseStats === "LIBERADO":
                dataIconsCopy[coursePosition] = "fas fa-unlock";
                setDataIcons([...dataIconsCopy]);
                break;
            case courseStats === "CONCLUIDO":
                dataIconsCopy[coursePosition] = "fas fa-check-circle";
                setDataIcons([...dataIconsCopy]);
                break;
            case courseStats === "EM_ANDAMENTO":
                dataIconsCopy[coursePosition] = "far fa-check-circle";
                setDataIcons([...dataIconsCopy]);
                break;
            case courseStats === "BLOQUEADO":
                dataIconsCopy[coursePosition] = "fas fa-lock";
                setDataIcons([...dataIconsCopy]);
                break;
            default:
                dataIconsCopy[coursePosition] = "fas fa-lock";
                setDataIcons([...dataIconsCopy]);
                break;
        }
    };

    const handleClick = async (event, coursePosition) => {
        event.preventDefault();
        const courseStats = await getCourseStats(coursePosition);

        if (courseStats === "LIBERADO") {
            setCurrentCoursePosition(coursePosition);
        } else if (courseStats === "EM_ANDAMENTO") {
            setCurrentCoursePosition(coursePosition);
        } else if (courseStats === "CONCLUIDO") {
            setCurrentCoursePosition(coursePosition);
        }

    };

    const settings = {
        dots: true,
        arrows: true,
        accessibility: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    if (!trail || !trail.trails_courses || !trail.trails_courses.length) return null;
    return (
        <Container>
            <Title>{trail.title || ""}</Title>
            <Slider {...settings} style={{ marginTop: "25px" }}>
                {courses.map((course, index) => (
                    <Col key={`otherCourses-${course.id}`}>
                        <Icon className={dataIcons[index]} />
                        <Card onClick={(e) => handleClick(e, index)}>
                            <CardCourses course={course.course} isCurrentCourse={index === currentCoursePosition ? true : false} />
                        </Card>
                    </Col>
                ))}
            </Slider>
        </Container>
    );
};

export default OtherCourses;
