import Cookie from "js-cookie";

export const getToken = () => {
  return Cookie.get(process.env.REACT_APP_TOKEN);
};

export const redirectToLogout = (msg = "") => {
  localStorage.clear();
  Cookie.remove(process.env.REACT_APP_TOKEN);
  window.location = `${process.env.REACT_APP_SIGNIN_URL}/logout?${btoa(
    document.location.origin
  )}${msg ? `&msg=${msg}` : ""}`;
};

export const redirectToSignUp = (msg = "") => {
  localStorage.clear();
  Cookie.remove(process.env.REACT_APP_TOKEN);
  window.location = `${process.env.REACT_APP_SIGNIN_URL}/signUp?${btoa(
    document.location.origin
  )}${msg ? `&msg=${msg}` : ""}`;
};
