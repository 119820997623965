import React, { useEffect, useState } from "react";
import { getPagesBySlug } from "../services/endpoints/cms/pages";
import { snakeToPascalCase } from "../utils/manipulateString";
import { PageFactory, PageNotFound } from "./Pages";

const PageBase = ({ match }) => {
	const slug = match.params.slug ? match.params.slug.toLowerCase() : "home";
	const [page, setPage] = useState({});

	useEffect(() => {
		const loadPage = async () => {
			try {
				const { data } = await getPagesBySlug(slug);
				setPage(data[0] || null);
			} catch (err) {
				console.error(err);
			}
		};

		loadPage();
	}, [slug]);

	return (
		<>
			{page ? (
				page.title && (
					<PageFactory pageName={snakeToPascalCase(page.title)} page={page} />
				)
			) : (
					<PageNotFound />
				)}
		</>
	);
};

export default PageBase;
