import React from "react";
import { stripTags } from "../../utils/manipulateString";
import { Container, Content, Title, Description } from "./styles";
import Placeholder from "../../assets/images/placeholder.jpg";

const CoursesBanner = ({ content }) => {
  if (!content) return null;
  return (
    <Container url={(content.file && content.file.urlPublica) || Placeholder}>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: stripTags(content.title) }} />
        <Description
          dangerouslySetInnerHTML={{ __html: stripTags(content.description) }}
        />
      </Content>
    </Container>
  );
};

export default CoursesBanner;
