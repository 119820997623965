import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Note = styled.div`
  margin: 10px 0 0;
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const NoteDetails = styled.div`
  flex: 1;
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Name = styled.p`
  margin: 0 0 10px;
  color: #333;
  font-size: 13px;
`;

export const CreatedAt = styled.span`
  margin: 0 0 10px;
  font-size: 10px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #333;
`;
