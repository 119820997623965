import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const Title = styled.div`
  padding: 10px 0;
  color: #fff;
  font-size: 14px;
`;

export const Icon = styled.i`
  padding: 5px 0 0 5px;
  color: #fff;
  font-size: 13px;
  line-height: 1px;
  text-align: center;
`;

export const Dropdown = styled.div`
  padding: 10px;
  background-color: #fff;
  border-radius: 18px;
  & a {
    padding: 10px 0;
    color: #333;
  }
`;
