import React, { useState, useEffect } from "react";
import { getCommentsByLessonIdAndUserId } from "../../../services/endpoints/general/comments";
import { getCurrentUser } from "../../../utils";
import { Container } from "./styles.js";
import CreateNote from "./CreateNote";
import NoteList from "./NoteList";
import { Spinner } from "reactstrap";

const Notes = ({ currentLessonId }) => {
	const [comments, setComments] = useState([]);
	const user = getCurrentUser();
	const [reload, setReload] = useState(false);
	const [loading, setLoad] = useState(false);

	useEffect(() => {
		const loadData = async () => {
			try {
				const { data } = await getCommentsByLessonIdAndUserId(currentLessonId, user.id);
				setComments(data || []);
				setLoad(false);
			} catch (err) {
				console.error(err);
				setLoad(false);
			}
		};
		setLoad(true);
		setTimeout(() => {
			loadData();
		}, 2000);
	}, [currentLessonId, user.id, reload]);

	return (
		<Container>
			<CreateNote currentLessonId={currentLessonId} user={user} setReload={setReload} />
			{loading ? <Spinner className="m-5" color="primary" /> : <NoteList notes={comments} />}
		</Container>
	);
};

export default Notes;
