import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Icon } from "./styles";

const Search = () => {
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    history.push(`/search/${keyword}`);
  };

  return (
    <Form isVisible={isVisible} onSubmit={(e) => handleSubmit(e)}>
      <Input
        type='search'
        required
        isVisible={isVisible}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Button type='submit' onClick={(e) => setIsVisible((prev) => !prev)}>
        <Icon className='fas fa-search' />
      </Button>
    </Form>
  );
};

export default Search;
