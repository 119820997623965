import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Container, Title, Icon, PrevArrow, NextArrow } from "./styles";
import { CardLessons } from "../../Components";
import { getExamLessonApproval } from "../../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../../utils";
import Slider from "react-slick";

const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return <PrevArrow className={className} onClick={onClick} />;
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;

    return <NextArrow className={className} onClick={onClick} />;
};

const OtherLessons = ({
    lessons,
    currentLessonPosition,
    setCurrentLessonPosition,
    refreshCourse
}) => {

    const [dataIcons, setDataIcons] = useState([]);

    useEffect(() => {

        const loadIcons = () => {
            lessons.map(async (lesson, index) => {
                handleIconType(index);
            });
        }

        loadIcons();

    }, [lessons, currentLessonPosition, refreshCourse]);


    const getResultExam = async (lessonId, exam) => {
        try {
            const { data } = await getExamLessonApproval(getCurrentUser().id, lessonId, exam.id);
            return (data && data.length ? data : null);
        } catch (e) {
            console.error("Exam.onSubmit", e);
        }
    }

    const getLessonsStats = async (lessonPosition) => {

        const concluido = "CONCLUIDO";
        const liberado = "LIBERADO";
        const emAndamento = "EM_ANDAMENTO";
        const bloqueado = "BLOQUEADO";

        const lessonStats = (
            lessons &&
                lessons[lessonPosition] &&
                lessons[lessonPosition].lesson_stats ?
                lessons[lessonPosition].lesson_stats :
                null
        );

        const examsLesson = (
            lessonStats &&
                lessons[lessonPosition].lesson_exams &&
                lessons[lessonPosition].lesson_exams ?
                lessons[lessonPosition].lesson_exams : null);

        const previousLesson = (lessons[lessonPosition - 1] && lessons[lessonPosition] ? lessons[lessonPosition - 1] : null);

        if (await checkedLessonUnlocked(lessonStats, previousLesson, lessonPosition)) {
            return liberado;
        } else if (lessonStats && lessonStats.length > 0 && !(await checkedLessonInProgress(lessons[lessonPosition]))) {
            return emAndamento;
        } else if (await checkLessonConcluded(examsLesson, lessonStats, lessonPosition)) {
            return concluido;
        } else if (checkBlocked(lessonStats, previousLesson, emAndamento)) {
            return bloqueado;
        }

    }

    const checkedLessonInProgress = async (lesson) => {
        const existExamsLesson = (
            lesson &&
                lesson.lesson_exams &&
                lesson.lesson_exams.length ?
                lesson.lesson_exams : null
        );

        let resultExamLesson = null;
        if (existExamsLesson) {
            const resultsExams = await Promise.all(existExamsLesson.map(async (lessonExam) => await checkExam(lessonExam, lesson)));
            resultExamLesson = resultsExams.every((res) => !!res);
        } else {
            resultExamLesson = true;
        }

        return (
            resultExamLesson
            &&
            (
                lesson.lesson_stats &&
                lesson.lesson_stats[0] &&
                lesson.lesson_stats[0].lesson_status === "CONCLUIDO"
            )
        );
    }

    const checkedLessonUnlocked = async (lessonStats, previousLesson, lessonPosition) => {

        const existExamsPreviousLesson = (
            previousLesson &&
                previousLesson.lesson_exams &&
                previousLesson.lesson_exams.length ?
                previousLesson.lesson_exams : null
        );

        let resultExamPreviousLesson = null;
        if (existExamsPreviousLesson) {
            const resultsExams = await Promise.all(existExamsPreviousLesson.map(async (lessonExam) => await checkExam(lessonExam, previousLesson)));
            resultExamPreviousLesson = resultsExams.every((res) => !!res);
        } else {
            resultExamPreviousLesson = true;
        }

        return (

            (
                lessonStats && lessonStats.length === 0 && lessonPosition === 0
            ) ||

            (
                lessonStats && lessonStats.length === 0 &&
                previousLesson &&
                previousLesson.lesson_stats &&
                previousLesson.lesson_stats[0] &&
                previousLesson.lesson_stats[0].lesson_status === "CONCLUIDO" &&
                resultExamPreviousLesson
            )

        );

    }

    const checkLessonConcluded = async (examsLesson, lessonStats, lessonPosition) => {

        let resultExamLesson = null;
        if (examsLesson && examsLesson.length) {
            const resultsExams = await Promise.all(examsLesson.map(async (lessonExam) => await checkExam(lessonExam, lessons[lessonPosition])));
            resultExamLesson = resultsExams.every((res) => !!res);
        }

        if (examsLesson && examsLesson.length > 0 && resultExamLesson) {
            return resultExamLesson;
        }

        return examsLesson && examsLesson.length === 0 && lessonStats && lessonStats.length > 0 && lessonStats[0].progress === 100;

    }

    const checkBlocked = (lessonStats, previousLesson, emAndamento) => {

        return (
            (
                lessonStats && lessonStats.length > 0 && lessonStats[0].progress !== 100 &&
                previousLesson &&
                previousLesson.lesson_stats.length &&
                previousLesson.lesson_stats[0].lesson_status === emAndamento)
        )
            ||

            (
                lessonStats && lessonStats.length === 0 &&
                previousLesson &&
                previousLesson.lesson_stats.length === 0
            )

    }

    const checkExam = async (lessonExam, previousLesson) => {

        const resultExam = await getResultExam(previousLesson.id, lessonExam.exam);

        if (!resultExam) {
            return false;
        }

        if (resultExam && resultExam.length && lessonExam.exam.mandatory_approval && resultExam[0].approved) {
            return true;
        }

        if (!lessonExam.exam.mandatory_approval && resultExam && resultExam.length) {
            return true;
        }

    }

    const handleIconType = async (lessonPosition) => {

        const lessonStats = await getLessonsStats(lessonPosition);

        let dataIconsCopy = dataIcons;

        switch (true) {
            case lessonStats === "LIBERADO":
                dataIconsCopy[lessonPosition] = "fas fa-unlock";
                setDataIcons([...dataIconsCopy]);
                break;
            case lessonStats === "CONCLUIDO":
                dataIconsCopy[lessonPosition] = "fas fa-check-circle";
                setDataIcons([...dataIconsCopy]);
                break;
            case lessonStats === "EM_ANDAMENTO":
                dataIconsCopy[lessonPosition] = "far fa-check-circle";
                setDataIcons([...dataIconsCopy]);
                break;
            case lessonStats === "BLOQUEADO":
                dataIconsCopy[lessonPosition] = "fas fa-lock";
                setDataIcons([...dataIconsCopy]);
                break;
            default:
                dataIconsCopy[lessonPosition] = "fas fa-lock";
                setDataIcons([...dataIconsCopy]);
                break;
        }

    };

    const handleClick = async (event, lessonPosition) => {
        event.preventDefault();
        const lessonStats = await getLessonsStats(lessonPosition);

        if (lessonStats === "LIBERADO") {
            setCurrentLessonPosition(lessonPosition);
        } else if (lessonStats === "EM_ANDAMENTO") {
            setCurrentLessonPosition(lessonPosition);
        } else if (lessonStats === "CONCLUIDO") {
            setCurrentLessonPosition(lessonPosition);
        }

    };

    const settings = {
        dots: true,
        arrows: true,
        accessibility: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    if (!lessons || !lessons.length) return null;

    return (
        <Container>

            {lessons.length > 1 ?
                <Title>Outras aulas</Title>
                :
                <Title>Esse curso não tem mais aulas</Title>
            }
            <Slider {...settings} style={{ marginBottom: "46px" }}>
                {lessons.map(
                    (lesson, index) =>
                        <Col
                            key={`other-lesson-card-${lesson.id}`}
                            onClick={(e) => handleClick(e, index)}
                        >
                            <Icon className={dataIcons[index]} />
                            <CardLessons lesson={lesson} isCurrentLesson={index === currentLessonPosition ? true : false} />
                        </Col>
                )}
            </Slider>
        </Container>
    );
};

export default OtherLessons;
