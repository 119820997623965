import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 70px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-weight: 700;
`;

export const Input = styled.input`
  margin: 5px 0 10px;
  padding: 8px 12px;
  width: 100%;
  height: 38px;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 18px;

  &:focus {
    border-color: #3898ec;
    outline: 0;
  }
`;

export const TextArea = styled.textarea`
  padding: 8px 12px 82px;
  margin: 5px -6px 10px 0px;
  width: 100%;
  max-width: 100%;
  height: 141px;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 18px;
  overflow: auto;

  &:focus {
    border-color: #3898ec;
    outline: 0;
  }
`;

export const Button = styled.button`
  margin: 0 0 0 auto;
  padding: 14px 24px;
  text-decoration: none;
  display: block;
  border: 1px solid var(--primary-color);
  border-radius: 18px;
  background-color: #fff;
  color: var(--primary-color);
  font-size: 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-color);
    border-color: transparent;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;
