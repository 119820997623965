import styled from "styled-components";

export const PrevArrow = styled.div`
z-index: 2;
padding: 10px;
width: 50px;
height: 50px;
position: absolute:
top: 50%;
left: 1%;
text-align: center;
&:before {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 30px;
  font-family: "Font Awesome 5 Free"; 
  font-weight: 600;
  content: "\f104";
  opacity: 1;
}
`;

export const NextArrow = styled.div`
z-index: 2;
padding: 10px;
width: 50px;
height: 50px;
position: absolute:
top: 50%;
right: 1%;
text-align: center;
&:before {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 30px;
  font-family: "Font Awesome 5 Free"; 
  font-weight: 700;
  content: "\f105";
  opacity: 1;
}
`;

export const Dots = styled.ul`
  z-index: 2;
  top: 95%;
  list-style-type: none;

  & li {
    margin: 0 1px;
  }

  & li button:before {
    color: #ffffff;
    font-size: 13px;
  }

  & li.slick-active button:before {
    opacity: 1;
    color: #ffffff;
    font-size: 13px;
  }
`;
