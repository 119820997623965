import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from "query-string";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

function CategoriesFilter({ categoriesFilter }) {

	const [categories] = useState(categoriesFilter || []);
	const history = useHistory();
	const [selected, setSelected] = useState(null);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(prevState => !prevState)

	useEffect(() => {
		let filter = queryString.parse(window.location.search);
		setSelected(
			filter && filter.category_id && categories.length > 0
				? categories.find(
					(category) => `${category.id}` === `${filter.category_id}`
				).title
				: "Categorias"
		);
	}, [history.location]);


	const handleCategoryClick = async (event, categoryId) => {
		event.preventDefault();
		const params = { category_id: categoryId, page: 0 };
		let filter = queryString.parse(window.location.search);
		if (filter && params) {
			filter = Object.assign(filter, {
				...params,
			});
		}
		history.push({
			search: queryString.stringify(filter),
		});
	};

	const removeAllFilter = () => {
		history.push({
			search: queryString.stringify({}),
		});
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle color="white" caret onClick={(e) => removeAllFilter()}>
				{selected}
			</DropdownToggle>
			<DropdownMenu>
				{
					categories.map((category) => {
						return <DropdownItem
							key={`category-${category.id}`}
							onClick={(e) => handleCategoryClick(e, category.id)}
						>{category.title}</DropdownItem>
					})
				}
			</DropdownMenu>
		</Dropdown>
	);
}

export default CategoriesFilter;