import styled from "styled-components";

export const Container = styled.div`
  margin: 30px 0 0;
  padding: 40px 31px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  border-radius: 16px;
  background-color: #f8f8f8;
`;

export const Title = styled.h5`
  margin: 0 0 30px;
  color: #333;
  font-size: 25px;
  font-weight: 600;
`;
