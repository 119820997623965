import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0 20px 0;
  padding: 0 30px 14px;
  border: 1px solid #eee;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
  overflow: hidden;
  max-height: ${({ isVisible }) => (isVisible ? "3000px" : "80px")};
  transition: all 0.4s ease-in-out;
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
  transform: rotate(${({ isVisible }) => (isVisible ? "180deg" : "0")});
  transition: transform 0.2s ease;
`;

export const Question = styled.h4`
  flex: 1;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  line-height: 30px;
	cursor: pointer;
	margin-bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Answer = styled.p`
  margin: 0 0 10px;
  overflow: hidden;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
`;
