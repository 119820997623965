import styled from "styled-components";

export const Container = styled.div`
  & a {
    all: unset;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: ${({ childrenPosition }) => {
		switch (childrenPosition) {
			case "left":
				return "flex-start";
			case "right":
				return "flex-end";
			default:
				return "center";
		}
	}};
    color: ${({ textColor }) => textColor || "#333"};
    font-size: 14px;
    cursor: pointer;
  }
`;

export const LinkAnchor = styled.a``;

export const Title = styled.p``;
