import styled from "styled-components";
import { GlobalContainer } from "../../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin-top: 40px;
  margin-bottom: 40px;

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Title = styled.h5`
  color: #333;
  font-size: 30px;
  font-weight: 600;
`;

export const Icon = styled.i`
  margin: 35px 0 0;
  width: 100%;
  position: relative;
  color: var(--primary-color);
  font-size: 16px;
  text-align: center;
`;

export const Card = styled.div`
  width: 100%;
  height: auto;
`;

export const PrevArrow = styled.div`
    z-index: 1;
    text-align: center;
    color: #000;
    &:before {
        color: #000;
        font-size: 30px;
        font-family: "Font Awesome 5 Free"; 
        font-weight: 700;
        content: "\f104";
        opacity: 1;
    }
`;


export const NextArrow = styled.div`
    z-index: 1;
    top: 50%;
    color: #000;
    &:before {
        color: #000;
        font-size: 30px;
        font-family: "Font Awesome 5 Free"; 
        font-weight: 700;
        content: "\f105";
        opacity: 1;
    }
`;