import React from "react";

import { Container } from "./styles";
import { LinkHandler } from "../../../../../Components";

const MenuItem = ({ item }) => {
  if (!item) return null;
  return (
    <Container>
      <LinkHandler
        url={item.url}
        target={item.target}
        title={item.title}
        childrenPosition='left'
      />
    </Container>
  );
};

export default MenuItem;
