import styled from "styled-components";

export const ExamForm = styled.div``;

export const Questions = styled.div``;

export const QuestionGroup = styled.div`
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: 0 2px 13px -8px #646262;
  transition: box-shadow 300ms ease-in-out;
  &:hover {
    box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  }
`;

export const Question = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  color: #0f4dbc;
  font-size: 18px;
  i {
    margin-left: 10px;
  }
`;

export const Alternatives = styled.div`
  padding: 30px 0;
`;

export const Alternative = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid rgba(221, 221, 221, 0.82);
  border-radius: 8px;
  margin-bottom: 10px;

  & > input {
    margin: 0 10px 0 0;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  // padding: 0 35px;
  border: 1px solid #00985f;
  color: #00985f;
  border-radius: 30px;
  line-height: 40px;
  font-size: 12px;
  background: none;
  text-decoration: none;
  display: block;
  max-width: 180px;
  margin: auto;
  text-align: center;
  transition: all 500ms ease;
  &:hover {
    border-color: transparent;
    background-color: #00985f;
    color: #ffffff;
    -webkit-transform: translate(0px, -3px);
    -ms-transform: translate(0px, -3px);
    transform: translate(0px, -3px);
  }
`;

export const ButtonSubmit = styled.div`
  cursor: pointer;
  // padding: 0 35px;
  border: 1px solid #00985f;
  color: #ffffff;
  background: #00985f;
  border-radius: 30px;
  line-height: 40px;
  font-size: 12px;
  text-decoration: none;
  display: block;
  max-width: 180px;
  margin: auto;
  text-align: center;
  transition: all 500ms ease;
  &:hover {
    border-color: transparent;
    background-color: #00985f;
    color: #ffffff;
    -webkit-transform: translate(0px, -3px);
    -ms-transform: translate(0px, -3px);
    transform: translate(0px, -3px);
  }
`;

export const Progress = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    width: 80%;
  }
  margin: 0 auto;
  padding: 90px 0;
  display: flex;
  align-items: center;
`;

export const ProgressLabel = styled.div`
  font-weight: bold;
`;

export const ProgressBar = styled.div`
  width: 100%;
  background: #dbdbdb;
  margin-left: 30px;
  height: 10px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #00985f;
    width: ${(props) => props.width}%;
  }
`;
