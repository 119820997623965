import styled from "styled-components";
import { GlobalContainer } from "../../styles/GlobalStyles";

export const Container = styled(GlobalContainer)`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Banner = styled.img`
  margin: 30px 0 60px;
  width: 100%;
  max-width: 100%;
  height: auto;
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #333;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
`;
