import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { getCurrentUser } from "../../utils";
import { formatNumber } from "../../utils/manipulateString";
import { Container, Item, ProfilePicture, Title, Span } from "./styles";
import { PageTitle } from "../../styles/GlobalStyles";
import NoProfilePicture from "../../assets/images/no-profile-picture-icon.jpg";

const UserInfo = ({ content }) => {
	const [user] = useState(getCurrentUser() || {});

	if (!content) return null;
	return (
		<Container>
			<PageTitle>Perfil</PageTitle>
			<Row>
				<Col xs='12' lg='3'>
					<Item>
						<ProfilePicture url={user.image || NoProfilePicture} />
						<Span>{user.name || ""}</Span>
					</Item>
				</Col>
				<Col xs='12' lg='3'>
					<Item>
						<Title>{formatNumber(content.exams)}</Title>
						<Span>Provas realizadas</Span>
					</Item>
				</Col>
				<Col xs='12' lg='3'>
					<Item>
						<Title>{formatNumber(content.videos)}h</Title>
						<Span>Vídeos Assistidos</Span>
					</Item>
				</Col>
				<Col xs='12' lg='3'>
					<Item>
						<Title>{formatNumber(content.certificates)}</Title>
						<Span>Certificados</Span>
					</Item>
				</Col>
			</Row>
		</Container>
	);
};

export default UserInfo;
