import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useExamContext } from "../../../contexts/ExamProvider";
import { getExamLessonApproval } from "../../../services/endpoints/lms/exams";
import { getCurrentUser } from "../../../utils";
import { Title, Button } from "./styles";
import { Row } from "reactstrap";
import Modal from "../../Modal";

const CongratsMessageLesson = ({ exam, parentId, nameExam }) => {
	const history = useHistory();
	const { setExamId, setParentId, setTypeExam } = useExamContext();
	const [resultExam, setResultExam] = useState();
	const [visibleAlert, setVisibleAlert] = useState(false);
	const onDismiss = () => setVisibleAlert(false);

	const handleClick = (event) => {
		event.preventDefault();

		setExamId(exam.id);
		setParentId(parentId);
		setTypeExam("lesson");
		history.push(`${history.location.pathname}/prova`);
	};

	useEffect(() => {
		const loadResultExam = async () => {
			try {
				const { data } = await getExamLessonApproval(getCurrentUser().id, parentId, exam.id);
				setResultExam(data && data.length > 0 ? data[0] : null);
			} catch (e) {
				console.error("Exam.onSubmit", e);
				setVisibleAlert(true);
			}
		};

		loadResultExam();
	}, [parentId, exam.id]);

	if (!exam || !parentId) return null;
	return (
		<>
			<Modal open={visibleAlert} toggle={onDismiss} typeMessage="danger">
				Ocorreu um erro ao enviar as respostas. Tente novamente mais tarde.
			</Modal>
			<Row>
				<Button approved={resultExam && resultExam.approved} onClick={(e) => handleClick(e)}>
					<Title>{nameExam}</Title>
					{resultExam && resultExam.approved ?
						(
							<>
								<Title>Aprovado</Title>
								<Title>{resultExam.grade_obtainedStr}</Title>
							</>
						)
						:
						(
							<Title>Fazer avaliação</Title>
						)
					}
				</Button>
			</Row>
		</>
	);
};

export default CongratsMessageLesson;
